export default {
  "fieldset": "cJo",
  "subscription-period": "cJn",
  "automatic-number-section": "cJi",
  "automatic-number-label": "cJA",
  "icon-tooltip": "cJY",
  "date-picker": "cJh",
  "label": "cJf",
  "header-text-field": "cJK",
  "header-text-field-container": "cJG",
  "header-text-field-add-button": "cJr btn btn--tertiary",
  "header-text-field-close-button": "cJb btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "cJy",
  "read-only": "cJU"
};
