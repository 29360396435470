export default {
  "label-wrapper": "jB body-1",
  "avatar": "jE",
  "container": "jd",
  "transaction-info": "jI",
  "secondary": "jt",
  "amount-container": "jM",
  "amount": "jP",
  "disabled": "jl",
  "checkbox": "jX",
  "disclaimer": "jC"
};
