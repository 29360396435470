export default {
  "mt-16": "cLR",
  "title": "cLQ",
  "edit-organization-form": "cLe",
  "beside-block": "cLB",
  "info-circle": "cLE",
  "hollow": "cLd",
  "filled": "cLI",
  "radio": "cLt",
  "cancel-button": "cLM",
  "error-message": "cLP"
};
