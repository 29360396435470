export default {
  "journey": "cQA",
  "content": "cQY",
  "distance": "cQh",
  "google-link-container": "cQf",
  "link-icon": "cQK",
  "continue-btn": "cQG",
  "origin": "cQr",
  "input-width": "cQb"
};
