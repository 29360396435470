export default {
  "container": "cCB",
  "logo": "cCE",
  "title": "cCd title-4",
  "description": "cCI body-2",
  "metadata": "cCt",
  "risks-container": "cCM",
  "label": "cCP caption",
  "risks": "cCl",
  "tag": "cCX tag"
};
