export default {
  "mandates": "vA",
  "mandates-list": "vY",
  "left-section": "vh",
  "header-search": "vf",
  "header": "vK",
  "header-content": "vG",
  "search": "vr",
  "search-bar": "vb",
  "body": "vy",
  "list-title": "vU caption-bold",
  "details": "vj",
  "avatar": "vW mr-16",
  "header-beneficiary": "vu",
  "header-beneficiary-activity": "oS",
  "header-beneficiary-details": "oc",
  "header-beneficiary-details-recurring": "oq",
  "infos": "oZ",
  "infos-title": "oR",
  "infos-empty": "oQ",
  "infos-list": "oe",
  "infos-list-item": "oB",
  "infos-list-item-left": "oE",
  "infos-list-item-right": "od",
  "infos-list-item-attachment": "oI"
};
