export default {
  "container": "yE",
  "container-metal": "yd",
  "content": "yI",
  "button": "yt",
  "embossed-selector": "yM",
  "carousel-container": "yP",
  "carousel": "yl",
  "asset": "yX",
  "poster": "yC",
  "video-embossed": "yk",
  "video": "yJ",
  "active": "ys",
  "embossed": "yF",
  "safari": "yg",
  "poster-open": "yD"
};
