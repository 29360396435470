export default {
  "container": "ro",
  "back-button": "rn",
  "card-list": "ri",
  "upsell-badge": "rA",
  "pricing": "rY",
  "pricing-period": "rh",
  "ad-modal-button": "rf",
  "eligibility-disclaimer": "rK",
  "feature": "rG",
  "highlighted": "rr"
};
