export default {
  "wrapper": "cCF",
  "content": "cCg",
  "back": "cCD btn btn--tertiary",
  "heading": "cCV",
  "heading-content": "cCL",
  "logo": "cCT",
  "placeholder": "cCz",
  "description": "cCa body-2",
  "cta": "cCH"
};
