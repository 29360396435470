export default {
  "dates-filter": "AC",
  "dates-wrapper": "Ak",
  "date-interval": "AJ",
  "date-field-wrapper": "As",
  "dates-conditional": "AF",
  "placeholder": "Ag",
  "error": "AD",
  "error-message": "AV body-2"
};
