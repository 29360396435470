/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

const MEMBERSHIP_FIELDS = ['nationality'];

const USER_FIELDS = ['gender', 'birthCountry', 'birthCity', 'phoneNumber', 'birthdate'];

export default class KYCUserInfoController extends Component {
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service userManager;
  @service router;
  @service homePage;
  @service sentry;

  @tracked enableValidation = false;

  get membership() {
    return this.organizationManager.membership;
  }

  get currentUser() {
    return this.userManager.currentUser;
  }

  confirmTask = dropTask(async () => {
    let { validations: membershipValidation } = await this.membership.validate({
      on: MEMBERSHIP_FIELDS,
    });

    let { validations: userValidation } = await this.currentUser.validate({
      on: USER_FIELDS,
    });

    if (membershipValidation.isInvalid || userValidation.isInvalid) {
      this.enableValidation = true;
      return;
    }

    try {
      await this.currentUser.save();
      await this.membership.submitKYC(this.args.context.documents);

      this.router.transitionTo('kyc.start', {
        queryParams: {
          trackingEventOrigin: 'card_flow',
        },
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  updateBirthdate(value) {
    this.currentUser.birthdate = value;
  }

  @action
  handleGenderSelection(gender) {
    this.currentUser.gender = gender;
  }
}
