/* import __COLOCATED_TEMPLATE__ from './balance-amount.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

export default class QontoHubNotificationsCreateStepsBalanceAmountComponent extends Component {
  @service intl;
  @service toastFlashMessages;

  get amountInEUR() {
    return this.args.context.hubNotificationRule.value / 100;
  }

  get errorMessage() {
    if (this.args.context.hubNotificationRule.value <= 0) {
      return this.intl.t('validations.errors.blank');
    }

    return null;
  }

  @action
  changeValue(value) {
    let amount = parseInt(value, 10);

    this.args.context.hubNotificationRule.set('value', Number.isNaN(amount) ? 0 : amount * 100);
  }

  confirmAmountTask = task(async () => {
    try {
      await this.args.context.hubNotificationRule.save();

      this.args.transitionToNext();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });
}
