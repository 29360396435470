export default {
  "wrapper": "cBQ",
  "wrapper-revamp": "cBe",
  "container": "cBB",
  "container-revamp": "cBE",
  "box-wrapper": "cBd",
  "warning-label": "cBI",
  "subtitle-container": "cBt",
  "info-container": "cBM",
  "card": "cBP",
  "disclaimer": "cBl",
  "list": "cBX",
  "list-item": "cBC body-2",
  "total": "cBk"
};
