export default {
  "members-loading": "cVY",
  "members-loading--ff": "cVh",
  "header": "cVf",
  "header-search-bar": "cVK",
  "body": "cVG",
  "members": "cVr",
  "placeholder-container": "cVb",
  "icon": "cVy",
  "details": "cVU",
  "member-loading": "cVj",
  "member-loading--no-ff": "cVW",
  "body-members": "cVu",
  "body-details": "cLS"
};
