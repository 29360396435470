export default {
  "page-wrapper": "cSc",
  "container": "cSq",
  "content": "cSZ",
  "showElement": "cSR",
  "illustration": "cSQ",
  "fadeIn": "cSe",
  "scaleDown": "cSB",
  "error-content": "cSE",
  "error-illustration": "cSd"
};
