export default {
  "invitations-password-form": "cJC",
  "mobile-mode": "cJk",
  "logo": "cJJ",
  "header": "cJs",
  "controls": "cJF",
  "footer": "cJg",
  "submit-cta": "cJD",
  "subtitle": "cJV"
};
