export default {
  "filter": "Ac",
  "expression": "Aq",
  "conditional-wrapper": "AZ mr-8",
  "dropdown-property-select": "AR",
  "is-advanced-filters-mode": "AQ",
  "dropdown-operator-select": "Ae",
  "conditional-text": "AB",
  "property-select": "AE",
  "operator-select": "Ad",
  "error-message": "AI",
  "values-field": "At",
  "input-placeholder": "AM",
  "filter-actions": "AP",
  "label-color": "Al",
  "button-container": "AX"
};
