export default {
  "container": "cMF",
  "container--collapsed": "cMg",
  "spin": "cMD",
  "cta-tooltip": "cMV",
  "cta--collapsed": "cML",
  "cta--expanded": "cMT",
  "collapsed-svg-icon": "cMz",
  "expanded-svg-icon": "cMa",
  "generate-logo-cta": "cMH",
  "badge": "cMO",
  "scaleDown": "cMm",
  "fadeIn": "cMp",
  "textRiseButton": "cMx",
  "cta-description": "cMw",
  "textRiseDescription": "cMN"
};
