/* import __COLOCATED_TEMPLATE__ from './reason.hbs'; */
/* eslint-disable ember/no-side-effects */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { RadioButtonControlled, TextAreaField } from '@repo/design-system-kit';
import { wait } from 'ember-animated';
import { easeIn, easeOut } from 'ember-animated/easings/cosine';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { variation } from 'ember-launch-darkly';

export default class SubscriptionCloseReasonComponent extends Component {
  textAreaField = TextAreaField;
  radioButtonControlled = RadioButtonControlled;

  @tracked showError = false;
  @tracked selectedReason = null;
  @tracked bannerVisible = null;
  @tracked currentPlan = null;
  @tracked suggestedPlan = null;
  @tracked memoizedBannerType = null;

  @service intl;
  @service segment;
  @service subscriptionManager;
  @service router;
  @service organizationManager;
  @service networkManager;
  @service sentry;
  @service toastFlashMessages;
  @service store;

  constructor() {
    super(...arguments);
    this.selectedReason = this.args.context.selectedReason || null;
    this.bannerVisible = this.args.context.isBannerVisible || null;
    this.initializePlans();
  }

  async initializePlans() {
    try {
      let currentPlanGroup = this.subscriptionManager.currentPricePlan.groupCode;
      let suggestedPlanCode = this.suggestedDowngradePlan;

      let allPricePlans = await this.store.query('subscription-product', { type: 'core' });

      this.currentPlan = allPricePlans.find(plan => plan.code.includes(currentPlanGroup));
      this.suggestedPlan = suggestedPlanCode
        ? allPricePlans.find(plan => plan.code.includes(suggestedPlanCode))
        : null;

      this.memoizedBannerType = null;
    } catch (error) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  #formatPrice(price) {
    if (!price) return '';
    return this.intl.formatNumber(Number(price.perMonthAmount.value), {
      currency: price.perMonthAmount.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      notation: 'compact',
    });
  }

  getPlanPrice(planType, priceType) {
    let plan;
    switch (planType) {
      case 'current':
        plan = this.currentPlan;
        break;
      case 'suggested':
        plan = this.suggestedPlan;
        break;
      default:
        return '';
    }

    let price;
    switch (priceType) {
      case 'monthly':
        price = plan?.monthlyPrice;
        break;
      case 'annual':
        price = plan?.annualPrice;
        break;
      default:
        return '';
    }

    return this.#formatPrice(price);
  }

  get currentPlanPriceMonthly() {
    return this.getPlanPrice('current', 'monthly');
  }

  get currentPlanPriceAnnual() {
    return this.getPlanPrice('current', 'annual');
  }

  get suggestedPlanPriceMonthly() {
    return this.getPlanPrice('suggested', 'monthly');
  }

  get suggestedPlanPriceAnnual() {
    return this.getPlanPrice('suggested', 'annual');
  }

  get suggestedPlanBillingInfo() {
    if (!this.suggestedPlan || !this.suggestedPlan.annualPrice) return '';
    return this.suggestedPlan.annualPrice.billingRecurrence;
  }

  get currentPlanBillingInfo() {
    if (!this.currentPlan || !this.currentPlan.annualPrice) return '';
    return this.currentPlan.annualPrice.billingRecurrence;
  }

  get suggestedDowngradePlan() {
    let downgradeMapping = {
      solo_smart: 'solo_basic',
      solo_premium: 'solo_smart',
      team_business: 'team_essential',
      team_enterprise: 'team_business',
    };

    let currentPlanCode = this.subscriptionManager.currentPricePlan.groupCode;
    return downgradeMapping[currentPlanCode] || null;
  }

  get bannerType() {
    if (this.memoizedBannerType !== null) {
      return this.memoizedBannerType;
    }

    let { currentSubscription, currentPricePlan } = this.subscriptionManager;
    let recurrence = currentSubscription?.recurrence;
    let currentPlanGroup = currentPricePlan?.groupCode;
    let selectedReasonKey = this.selectedReason?.key;

    if (selectedReasonKey !== 'account_fees') {
      this.memoizedBannerType = null;
      return null;
    }

    let isPartOfABTest = variation('feature--improve-retention-ab-test');

    if (recurrence === 'monthly') {
      if (['solo_basic', 'team_essential'].includes(currentPlanGroup)) {
        this.trackEventOnce('account-closing_change-recurrence_displayed', {
          current_plan: currentPlanGroup,
        });
        this.memoizedBannerType = 'switch-to-annual';
        return 'switch-to-annual';
      } else {
        if (isPartOfABTest) {
          this.trackEventOnce('account-closing_change-recurrence_displayed', {
            current_plan: currentPlanGroup,
          });
          this.trackEventOnce('account-closing_subscription-fees-tactics_displayed', {
            Retention_tactic: 'change_recurrence',
          });
          this.memoizedBannerType = 'switch-to-annual';
          return 'switch-to-annual';
        } else {
          this.trackEventOnce('account-closing_switch-plan_displayed', {
            current_plan: currentPlanGroup,
            suggested_plan: this.suggestedDowngradePlan,
          });
          this.trackEventOnce('account-closing_subscription-fees-tactics_displayed', {
            Retention_tactic: 'switch_plan',
          });
          this.memoizedBannerType = 'downgrade-plan';
          return 'downgrade-plan';
        }
      }
    } else if (recurrence === 'annual') {
      if (!['solo_basic', 'team_essential'].includes(currentPlanGroup)) {
        this.trackEventOnce('account-closing_annual-switch-plan_displayed', {
          current_plan: currentPlanGroup,
          suggested_plan: this.suggestedDowngradePlan,
        });
        this.memoizedBannerType = 'annual-downgrade-plan';
        return 'annual-downgrade-plan';
      }
    }

    this.memoizedBannerType = null;
    return null;
  }

  trackEventOnce(eventName, data) {
    if (!this.segment.eventCache) {
      this.segment.eventCache = new Set();
    }
    if (!this.segment.eventCache.has(eventName)) {
      this.segment.track(eventName, data);
      this.segment.eventCache.add(eventName);
    }
  }

  get shouldShowBanner() {
    if (this.bannerType === 'downgrade-plan' || this.bannerType === 'annual-downgrade-plan') {
      this.initializePlans();
    }
    return this.bannerType !== null;
  }

  *customFadeTransition({ insertedSprites, removedSprites, duration = 75, delay = 25 }) {
    for (let sprite of insertedSprites) {
      yield wait(delay);
      fadeIn(sprite, { duration, easing: easeIn });
    }

    for (let sprite of removedSprites) {
      yield wait(delay);
      fadeOut(sprite, { duration, easing: easeOut });
    }
  }

  get hasError() {
    let { hasReasonError, subReasonError } = this;
    return hasReasonError || subReasonError;
  }

  get hasReasonError() {
    let { closingReasonSubCategory } = this.args.context;
    return !closingReasonSubCategory;
  }

  get subReasonError() {
    let { closingReasonDetails, closingReasonSubCategory } = this.args.context;
    return closingReasonSubCategory?.items?.length && !closingReasonDetails
      ? this.intl.t('subscription.account-closing.reason.select-reason')
      : null;
  }

  @action selectReason(item) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonSubCategory = item;
    context.closingReasonDetails = null;
    context.closingReasonDetailsOther = null;
    this.selectedReason = item;
    this.bannerVisible = this.shouldShowBanner;
    context.bannerVisible = this.bannerVisible;
    this.args.context.bannerType = this.bannerType;

    if (this.selectedReason?.key !== 'account_fees') {
      context.bannerVisible = false;
      context.bannerType = null;
    }
  }

  @action selectSubReason(item) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonDetails = item;
    context.closingReasonDetailsOther = null;
  }

  @action onSubmit(e) {
    e.preventDefault();
    if (this.hasError) {
      this.showError = true;
      return;
    }

    let { closingReasonDetails, closingReasonDetailsOther } = this.args.context;
    this.segment.track('account_closing_additional_details_completed', {
      reason_details: closingReasonDetails?.key,
      reason_details_other: closingReasonDetailsOther,
    });

    // Track continue closure based on banner type
    if (this.bannerType === 'switch-to-annual') {
      this.segment.track('account-closing_change-recurrence_continue-closure', {
        current_plan: this.currentPlan.localName,
      });
    } else if (this.bannerType === 'downgrade-plan') {
      this.segment.track('account-closing_switch-plan_continue-closure', {
        current_plan: this.currentPlan.localName,
      });
    } else if (this.bannerType === 'annual-downgrade-plan') {
      this.segment.track('account-closing_annual-switch-plan_continue-closure', {
        current_plan: this.currentPlan.localName,
      });
    }

    this.args.transitionToNext();
  }

  @action
  transitionToSubscription() {
    let recurrence =
      this.bannerType === 'switch-to-annual'
        ? 'annual'
        : this.subscriptionManager.currentSubscription?.recurrence;
    let changeType = '';

    if (this.bannerType === 'switch-to-annual' && this.currentPlan) {
      changeType = 'change-recurrence';
      this.segment.track('account-closing_change-recurrence_clicked', {
        current_plan: this.currentPlan.localName,
      });
    } else if (this.bannerType === 'downgrade-plan' && this.currentPlan && this.suggestedPlan) {
      changeType = 'switch-plan';
      this.segment.track('account-closing_switch-plan_clicked', {
        current_plan: this.currentPlan.localName,
        suggested_plan: this.suggestedPlan.localName,
      });
    } else if (
      this.bannerType === 'annual-downgrade-plan' &&
      this.currentPlan &&
      this.suggestedPlan
    ) {
      changeType = 'annual-switch-plan';
      this.segment.track('account-closing_annual-switch-plan_clicked', {
        current_plan: this.currentPlan.localName,
        suggested_plan: this.suggestedPlan.localName,
      });
    }

    this.args.transitionToFlow({
      flowName: 'subscription-change',
      stepId: 'plans',
      queryParams: {
        recurrence,
        changeType,
        currentPlan: this.currentPlan.localName,
      },
    });
  }
}
