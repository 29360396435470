export default {
  "step-container": "cZU",
  "subtitle": "cZj",
  "step-container-content": "cZW",
  "continue-button": "cZu",
  "period-select": "cRS",
  "period-dropdown": "cRc",
  "readonly": "cRq",
  "period-error": "cRZ",
  "toggle-options-container": "cRR",
  "receipt-code-toggle-input": "cRQ"
};
