export default {
  "wrapper": "nN",
  "legend": "nv",
  "input": "no",
  "table": "nn",
  "footer": "ni",
  "footer-total-m": "nA",
  "footer-total-n": "nY",
  "footer-balance": "nh"
};
