export default {
  "item": "cDi",
  "selected": "cDA",
  "hover-revised": "cDY",
  "asset-container": "cDh",
  "asset": "cDf",
  "revoked-icon": "cDK",
  "pending-icon": "cDG",
  "revoked": "cDr",
  "revised-revoke": "cDb",
  "pending": "cDy",
  "revised-pending": "cDU",
  "invitable": "cDj",
  "info": "cDW",
  "name": "cDu",
  "email": "cVS",
  "actions": "cVc",
  "invitable-member-delete": "cVq",
  "invitable-member-invite": "cVZ"
};
