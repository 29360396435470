/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

export default class FinancingPayLaterCockpitCompletedTableRowComponent extends Component {
  get isDeclined() {
    return (
      this.args.financing.isDeclined && variation('feature--boolean-pay-later-financing-status')
    );
  }
}
