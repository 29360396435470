/* import __COLOCATED_TEMPLATE__ from './capital-share-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

const CAPITAL_SHARE_LIMIT = 9999999999.99;

/* CapitalShareInputComponent is a component that allows the user to enter a capital share value.
 * It also handles the validation of the value.
 */
export default class CapitalShareInputComponent extends Component {
  @action
  updateCapitalShareValue(value) {
    // value cannot be negative
    let positiveValue = value >= 0 ? value : value * -1;

    // value has a max limit
    let positiveCappedValue =
      positiveValue <= CAPITAL_SHARE_LIMIT ? positiveValue : CAPITAL_SHARE_LIMIT;

    // value has a min limit: it needs to be greater than 0
    let legalCapitalShareValue = positiveCappedValue > 0 ? positiveCappedValue.toString() : null;

    this.args.updateAmount(legalCapitalShareValue);
  }

  @action
  disableStepKeyAndScroll(event) {
    event.target.addEventListener('keydown', this.shouldDisable);
    event.target.addEventListener('wheel', this.shouldDisable);
  }

  @action
  enableStepKeyAndScroll(event) {
    event.target.removeEventListener('keydown', this.shouldDisable);
    event.target.removeEventListener('wheel', this.shouldDisable);
  }

  @action
  shouldDisable(event) {
    // Prevent user from entering a decimal point
    if (event.keyCode === 38 || event.keyCode === 40 || event.type === 'wheel') {
      event.preventDefault();
    }
  }
}
