export default {
  "container": "csc",
  "form-container": "csq",
  "title": "csZ",
  "title-border": "csR",
  "tabs-container": "csQ",
  "preview-container": "cse",
  "email-preview-container": "csB",
  "close-button": "csE btn btn--icon-only btn--tertiary btn--large"
};
