/* import __COLOCATED_TEMPLATE__ from './date-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class F24DateField extends Component {
  @action
  updateBirthDate(value) {
    // As we now use DateFieldV2, the value we receive from the component
    // is formatted as a YYYY-MM-DD string date
    if (value) {
      this.args.onUpdate(value);
    }
  }
}
