export default {
  "container": "cew",
  "form-core": "ceN",
  "label": "cev",
  "request-description": "ceo",
  "inline-inputs": "cen",
  "placeholder-label": "cei",
  "placeholder-input": "ceA",
  "placeholder-checkbox": "ceY"
};
