export default {
  "container": "Ke",
  "numbering": "KB",
  "sideview": "KE",
  "form": "Kd",
  "numbering-fields": "KI",
  "row": "Kt",
  "label-tooltip": "KM",
  "tooltip-icon": "KP",
  "legend": "Kl",
  "next-invoice-number": "KX",
  "pdf-preview": "KC",
  "de-layout": "Kk",
  "de-pdf-preview": "KJ"
};
