export default {
  "sidebar-section": "cCO body-2",
  "sidebar-section-header": "cCm body-1 mb-4",
  "providers-logos": "cCp",
  "risks": "cCx",
  "tag": "cCw tag",
  "external-link-icon": "cCN",
  "disclaimer": "cCv",
  "divider": "cCo"
};
