export default {
  "row": "hr",
  "cell": "hb",
  "cell-content": "hy",
  "supplier": "hU",
  "next-installment": "hj",
  "installment-amount": "hW",
  "total-repayment": "hu",
  "cell-details": "fS",
  "align-right": "fc",
  "empty": "fq"
};
