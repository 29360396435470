/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { setProperties } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import {
  ERROR_CODE,
  ERROR_TRANSLATION_KEY,
  INVALID_ERROR_POINTER_PREFIX,
} from 'qonto/constants/international-out/beneficiary';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { State } from 'qonto/react/components/transfers/international-out/state';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  extractFieldKeyFromPointer,
  extractInvalidValueFromPayload,
} from 'qonto/utils/international-out/error';

export default class FlowsTransfersInternationalOutBeneficiaryCreateComponent extends Component {
  disclaimerBlock = Disclaimer.Block;
  state = State;

  @service internationalOutManager;
  @service intl;
  @service segment;
  @service sentry;
  @service toastFlashMessages;
  @service zendeskLocalization;

  @tracked requirements = [];
  @tracked validators = {};
  @tracked isError = false;

  constructor() {
    super(...arguments);
    this.loadInitialRequirementsTask.perform().catch(ignoreCancelation);
  }

  get notice() {
    switch (this.args.context.quote.targetCurrency) {
      case CURRENCIES.JPY:
        return {
          level: 'warning',
          content: this.intl.t('international-out.disclaimer.jpy.text', {
            link: htmlSafe(
              `<a
                href="${this.intl.t('international-out.disclaimer.jpy.link', {
                  faqUrl: this.zendeskLocalization.getLocalizedArticle(4987654),
                })}"
                target="_blank"
                rel="noopener noreferrer"
                class="body-link"
                data-test-faq-link
              >${this.intl.t('international-out.disclaimer.jpy.linkText')}</a>`
            ),
            htmlSafe: true,
          }),
        };
      default:
        return null;
    }
  }

  get paymentMethods() {
    return this.requirements.map(({ type }) => type);
  }

  createBeneficiaryTask = dropTask(async ({ type: paymentMethod, data: beneficiaryDetails }) => {
    this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_SUBMITTED);

    this.validators = {};

    let { context, transitionToNext } = this.args;
    let { id, targetCurrency } = context.quote;

    try {
      let {
        beneficiary,
        fees,
        quote: patchedQuote,
        targetAccountId,
      } = await this.internationalOutManager.createBeneficiary({
        quoteId: id,
        currency: targetCurrency,
        type: paymentMethod,
        details: beneficiaryDetails,
      });

      this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_ACCEPTED);

      setProperties(context, {
        beneficiary,
        fees,
        quote: patchedQuote,
        targetAccount: { id: targetAccountId },
      });

      transitionToNext();
    } catch (error) {
      let { status, errors } = error;

      if (status === 422) {
        errors.forEach(({ code, source }) => {
          let pointer = source?.pointer;
          if (pointer && Object.values(ERROR_CODE).includes(code)) {
            let fieldKey = extractFieldKeyFromPointer({
              pointer,
              prefix: INVALID_ERROR_POINTER_PREFIX,
            });
            let invalidValue = extractInvalidValueFromPayload({
              fieldKey,
              payload: beneficiaryDetails,
            });
            this.validators = {
              ...this.validators,
              [fieldKey]: {
                predicate: value => value !== invalidValue,
                translationKey: ERROR_TRANSLATION_KEY[code],
              },
            };
          }
        });

        if (Object.keys(this.validators).length) {
          return;
        }
      }

      this.#handleError(error);
    }
  });

  loadInitialRequirementsTask = dropTask(async () => {
    this.isError = false;

    try {
      this.requirements = await this.internationalOutManager.getBeneficiaryRequirements({
        quoteId: this.args.context.quote.id,
        currency: this.args.context.quote.targetCurrency,
      });
    } catch {
      this.isError = true;
    }
  });

  refreshRequirementsTask = dropTask(async ({ data: beneficiaryDetails }) => {
    try {
      this.requirements = await this.internationalOutManager.getBeneficiaryRequirements({
        quoteId: this.args.context.quote.id,
        currency: this.args.context.quote.targetCurrency,
        details: beneficiaryDetails,
      });
    } catch (error) {
      this.#handleError(error);
    }
  });

  #handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
