/* import __COLOCATED_TEMPLATE__ from './emitter.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FlowsCheckEmitterComponent extends Component {
  @service segment;

  @tracked highlightEmitterName = false;
  @tracked highlightEmittedDate = false;
  @tracked showNameErrorMessage = false;
  @tracked showDateErrorMessage = false;

  get emitterNameErrorMessage() {
    let { emitterName: emitterNameValidation } = this.args.context.check.validations.attrs;

    return emitterNameValidation.isInvalid && this.showNameErrorMessage
      ? emitterNameValidation.message
      : null;
  }

  get emittedDateErrorMessage() {
    let { emittedDate: emittedDateValidation } = this.args.context.check.validations.attrs;

    return emittedDateValidation.isInvalid && this.showDateErrorMessage
      ? emittedDateValidation.message
      : null;
  }

  get hasError() {
    let { check } = this.args.context;
    let { emittedDate: emittedDateValidation, emitterName: emitterNameValidation } =
      check.validations.attrs;

    return !check.emittedDate || emitterNameValidation.isInvalid || emittedDateValidation.isInvalid;
  }

  @action
  handleGoToDetailsPage(event) {
    event.preventDefault();
    let { emittedDate: emittedDateValidation } = this.args.context.check.validations.attrs;

    if (emittedDateValidation.isInvalid) this.showDateErrorMessage = true;
    if (this.hasError) return;

    this.segment.track('checks_deposit_issuer_and_date_submitted');
    return this.args.transitionToNext();
  }

  @action
  handleEmitterNameFocusIn() {
    this.highlightEmitterName = true;
  }

  @action
  handleEmitterNameFocusOut() {
    this.highlightEmitterName = false;
    this.showNameErrorMessage = true;
  }

  @action
  handleEmitterNameKeyDown() {
    this.showNameErrorMessage = false;
  }

  @action
  handleEmitterNameUpdate(value) {
    this.args.context.check.emitterName = value;
  }

  @action
  handleEmittedDateFocusOut() {
    this.showDateErrorMessage = true;
    this.highlightEmittedDate = false;
  }

  @action
  handleEmittedDateFocusIn() {
    this.highlightEmittedDate = true;
  }

  @action
  handleEmittedDateKeyDown() {
    this.showDateErrorMessage = false;
  }
}
