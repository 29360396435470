export default {
  "member-details": "cgW",
  "header": "cgu",
  "no-email": "cDS",
  "header-img": "cDc",
  "invited-persona": "cDq",
  "revoked-persona": "cDZ",
  "invited-icon": "cDR",
  "revoked-icon": "cDQ",
  "revised": "cDe",
  "invitable-icon": "cDB",
  "dropdown": "cDE",
  "header-body": "cDd",
  "status": "cDI",
  "name": "cDt title-3",
  "email": "cDM caption-bold",
  "actions": "cDP body-2",
  "actions-cards": "cDl",
  "actions-transactions": "cDX",
  "body": "cDC",
  "expense-permissions-section": "cDk",
  "body-title": "cDJ title-4",
  "body-attr": "cDs",
  "body-label": "cDF",
  "attr-buttons": "cDg",
  "body-role": "cDD small",
  "details-actions": "cDV",
  "actions-item": "cDL",
  "disclaimer": "cDT",
  "permissions": "cDz",
  "permission": "cDa",
  "permission-label": "cDH",
  "permission-check": "cDO",
  "permission-missing": "cDm",
  "editable-section": "cDp",
  "hris-placeholder": "cDx",
  "imported-from": "cDw",
  "integration-logo": "cDN",
  "permission-value": "cDv",
  "tooltip": "cDo",
  "check-icon": "cDn"
};
