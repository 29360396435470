export default {
  "header": "YD",
  "header-top": "YV",
  "header-inner": "YL",
  "disclaimer": "YT mb-16",
  "header-main-wrapper": "Yz",
  "header-main": "Ya",
  "logo": "YH mr-16",
  "header-right": "YO",
  "partner-description": "Ym body-2"
};
