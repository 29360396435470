export default {
  "checks": "jh",
  "wrapper": "jf",
  "form": "jK",
  "title": "jG",
  "wrapper-body": "jr",
  "aside": "jb",
  "steps-container": "jy",
  "step": "jU",
  "container-dot": "jj",
  "text": "jW",
  "text-title": "ju",
  "text-desc": "WS",
  "start-button": "Wc"
};
