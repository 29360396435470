export default {
  "container": "cCb",
  "header": "cCy",
  "content": "cCU",
  "info-list": "cCj",
  "info-label": "cCW body-2",
  "info-value": "cCu body-2",
  "provider": "ckS",
  "policy-container": "ckc",
  "policy-label": "ckq body-2",
  "policy-number": "ckZ body-2",
  "copy-to-clipboard": "ckR",
  "button": "ckQ btn btn--primary btn--stretch btn--large"
};
