/* import __COLOCATED_TEMPLATE__ from './numbering.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import {
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsAccountReceivableOnboardingNumbering extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service sentry;
  @service segment;
  @service toastFlashMessages;
  @service intl;

  constructor() {
    super(...arguments);

    this.#prefillSettings();
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (!this.settings || this.settings.isDestroyed || this.settings.isDestroying) return;

    this.settings.rollbackAttributes();
  }

  get settings() {
    return this.args.context.settings;
  }

  get onboardingState() {
    return this.args.context.onboardingState;
  }

  get invoiceNumberingPattern() {
    return this.settings?.invoiceNumberingPattern;
  }

  get invoiceNextNumber() {
    return this.settings.invoiceNextNumber;
  }

  get errors() {
    return this.settings.errors ?? {};
  }

  get nextInvoiceNumberPreview() {
    let month = (new Date().getMonth() + 1).toString().padStart(2, '0');
    let fullYear = new Date().getFullYear();

    let prefix =
      this.invoiceNumberingPattern
        ?.replace(/\(MM\)/g, month)
        .replace(/\(YYYY\)|\(AAAA\)|\(JJJJ\)/g, fullYear) ?? '';

    let invoiceNumber = this.invoiceNextNumber ?? '';

    return prefix + invoiceNumber;
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: ['number'],
    };
  }

  get exampleInvoice() {
    if (!this.args.context.isGermanOrganization) {
      return {
        number: this.nextInvoiceNumberPreview,
      };
    }

    return {
      number: this.nextInvoiceNumberPreview,
      issueDate: true,
      performanceDate: true,
      purchaseOrder: true,
      quote: {
        number: true,
      },
      customerSnapshot: {
        vatNumber: true,
        tinNumber: true,
      },
    };
  }

  @action
  updateInvoiceNumberingPattern(value) {
    this.settings.invoiceNumberingPattern = value;
  }

  /**
   * Our input only allows numbers, but FormFields::TextField doesnt support pattern
   * @param {*} number
   */
  @action
  updateInvoiceNextNumber(number) {
    this.settings.invoiceNextNumber = number;
    this.settings.invoiceNextNumber = number?.replace(/[^0-9]/g, '');
  }

  confirmTask = dropTask(async () => {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_COMPLETED, {
      source: this.args.context.source,
      step: 'numbering',
    });
    try {
      // Update settings
      await this.settings.save();

      // Update onboarding status
      this.onboardingState.stepStatusNumbering = ONBOARDING_STEP_STATUS.CONFIRMED;
      this.onboardingState.substepStatusInvoiceNumbering = ONBOARDING_STEP_STATUS.CONFIRMED;
      await this.onboardingState.save();

      this.args.backToStep('summary');
    } catch (error) {
      if (error.status !== 422) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  #prefillSettings() {
    if (this.onboardingState.stepStatusNumbering === ONBOARDING_STEP_STATUS.PREFILLED) {
      this.settings.setProperties({
        invoiceNumberingPattern: this.onboardingState.prefillData.invoiceNumberingPattern,
        invoiceNextNumber: this.onboardingState.prefillData.invoiceNextNumber,
      });
    }
  }
}
