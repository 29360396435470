export default {
  "header-cell": "csj",
  "empty": "csW",
  "header-content": "csu caption-bold",
  "active": "cFS",
  "align-right": "cFc",
  "first-col": "cFq",
  "mid-col": "cFZ",
  "status-col": "cFR"
};
