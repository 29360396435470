export default {
  "summary-description": "cIi",
  "detail": "cIA",
  "detail-value": "cIY",
  "account-icon": "cIh",
  "color-secondary": "cIf",
  "beneficiary-info": "cIK",
  "currency": "cIG",
  "beneficiary-name": "cIr",
  "separator": "cIb",
  "instant-subtitle": "cIy",
  "instant-wrapper": "cIU",
  "instant-header": "cIj",
  "instant-toggle": "cIW",
  "instant--disabled": "cIu",
  "limit-exceeded-disclaimer": "ctS",
  "sticky-panel": "ctc",
  "total-interest": "ctq",
  "tooltip-icon": "ctZ",
  "tooltip": "ctR",
  "payment-options": "ctQ"
};
