/* import __COLOCATED_TEMPLATE__ from './edit-beneficiary.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class FlowsTransfersSepaBeneficiaryEditBeneficiaryComponent extends Component {
  willDestroy() {
    super.willDestroy(...arguments);
    let { beneficiaryToEdit } = this.args.context;

    if (!beneficiaryToEdit || beneficiaryToEdit.isDestroyed || beneficiaryToEdit.isDestroying) {
      return;
    }

    beneficiaryToEdit.rollbackAttributes();
  }

  get beneficiary() {
    return this.args.context.beneficiaryToEdit;
  }

  @action
  transitionToNext(editedBeneficiary) {
    let { context, transitionToNext } = this.args;
    context.beneficiary = editedBeneficiary;
    transitionToNext();
  }
}
