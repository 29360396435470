export default {
  "step-container": "uo",
  "content": "un",
  "section": "ui",
  "section-content": "uA",
  "section-content-text": "uY",
  "section-illustration": "uh",
  "close-form-cta": "uf",
  "bank-account-select": "uK"
};
