export default {
  "card": "cPE",
  "text-container": "cPd",
  "text-title": "cPI title-3",
  "text-subtitle": "cPt body-1",
  "text-cta": "cPM body-1",
  "text-cta-icon": "cPP",
  "image": "cPl",
  "image-illustration": "cPX"
};
