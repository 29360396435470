export default {
  "wrapper": "nI",
  "legend": "nt",
  "operation-code": "nM",
  "table": "nP",
  "footer": "nl",
  "footer-detraction": "nX",
  "footer-total-g": "nC",
  "footer-total-h": "nk",
  "footer-net-g-h": "nJ"
};
