/* import __COLOCATED_TEMPLATE__ from './additional-requirements.hbs'; */
import { setProperties } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import {
  ERROR_CODE,
  ERROR_TRANSLATION_KEY,
  INVALID_ERROR_POINTER_PREFIX,
} from 'qonto/constants/international-out/beneficiary';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { ErrorInfo } from 'qonto/utils/error-info';
import {
  extractFieldKeyFromPointer,
  extractInvalidValueFromPayload,
} from 'qonto/utils/international-out/error';

export default class FlowsTransfersInternationalOutNewAdditionalRequirements extends Component {
  @service internationalOutManager;
  @service intl;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  @tracked requirements = this.args.context.additionalRequirements;
  @tracked validators = {};

  get paymentMethods() {
    return this.requirements.map(({ type }) => type);
  }

  get subtitle() {
    if (
      this.requirements.every(
        paymentMethod => paymentMethod.type !== this.args.context.beneficiary.paymentType
      )
    ) {
      return this.intl.t('international-out.additional-requirements.subtitle.create');
    }

    return this.intl.t('international-out.additional-requirements.subtitle.update');
  }

  refreshRequirementsTask = dropTask(async ({ data: beneficiaryDetails }) => {
    try {
      this.requirements = await this.internationalOutManager.getAdditionalBeneficiaryRequirements({
        quoteId: this.args.context.quote.id,
        beneficiary: this.args.context.beneficiary,
        details: beneficiaryDetails,
      });
    } catch (error) {
      this.#handleError(error);
    }
  });

  updateBeneficiaryTask = dropTask(async ({ type: paymentMethod, data: beneficiaryDetails }) => {
    this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_SUBMITTED);

    this.validators = {};

    let { context, transitionToNext } = this.args;
    let { beneficiary, quote } = context;

    try {
      let {
        beneficiary: updatedBeneficiary,
        fees,
        quote: patchedQuote,
        targetAccountId,
      } = await this.internationalOutManager.updateBeneficiary({
        quoteId: quote.id,
        beneficiaryId: beneficiary.id,
        currency: beneficiary.currency,
        type: paymentMethod,
        details: beneficiaryDetails,
      });

      this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_ACCEPTED);

      setProperties(context, {
        beneficiary: updatedBeneficiary,
        fees,
        quote: patchedQuote,
        targetAccount: { id: targetAccountId },
      });

      transitionToNext();
    } catch (error) {
      let { status, errors } = error;

      if (status === 422) {
        errors.forEach(({ code, source }) => {
          let pointer = source?.pointer;
          if (pointer && Object.values(ERROR_CODE).includes(code)) {
            let fieldKey = extractFieldKeyFromPointer({
              pointer,
              prefix: INVALID_ERROR_POINTER_PREFIX,
            });
            let invalidValue = extractInvalidValueFromPayload({
              fieldKey,
              payload: beneficiaryDetails,
            });
            this.validators = {
              ...this.validators,
              [fieldKey]: {
                predicate: value => value !== invalidValue,
                translationKey: ERROR_TRANSLATION_KEY[code],
              },
            };
          }
        });

        if (Object.keys(this.validators).length) {
          return;
        }
      }

      this.#handleError(error);
    }
  });

  #handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
