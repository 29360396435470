export default {
  "step-container": "cIs",
  "content": "cIF",
  "spinner": "cIg",
  "search": "cID",
  "placeholder": "cIV",
  "search-empty-state": "cIL",
  "error-state": "cIT",
  "beneficiaries-list": "cIz"
};
