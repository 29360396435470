export default {
  "container": "cdm",
  "content": "cdp",
  "quote-information-item": "cdx",
  "placeholder": "cdw",
  "divider": "cdN",
  "tooltip": "cdv",
  "amount": "cdo title-4",
  "align-left": "cdn",
  "sticky-panel": "cdi",
  "color-accent": "cdA",
  "color-secondary": "cdY"
};
