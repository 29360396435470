/* import __COLOCATED_TEMPLATE__ from './creditor-identifier.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

const CREDITOR_IDENTIFIER_VARIABLE_NAME = 'cid';
const TERMS_OF_SERVICE_VARIABLE_NAME = 'termsOfServiceAccepted';

export default class IntroComponent extends Component {
  @service intl;
  @service organizationManager;
  @service modals;
  @service router;
  @service toastFlashMessages;
  @service sentry;
  @service segment;
  @service zendeskLocalization;
  @reads('args.context.activation') activation;

  @tracked creditorIdentifierSelection = 'input';

  SUBTITLE_BY_LEGAL_COUNTRY = {
    FR: this.intl.t('sdd-collections.creditor-identifier.france.subtitle'),
    IT: this.intl.t('sdd-collections.creditor-identifier.italy.subtitle.text'),
    ES: this.intl.t('sdd-collections.creditor-identifier.spain.subtitle.text'),
    DE: this.intl.t('sdd-collections.creditor-identifier.germany.subtitle.text', {
      faqLink: htmlSafe(
        `<a
            href="${this.intl.t(
              'sdd-collections.creditor-identifier.germany.subtitle.how-to-link',
              {
                faqUrl: this.zendeskLocalization.getLocalizedArticle(9145314),
              }
            )}"
            target="_blank"
            rel="noopener noreferrer"
            class="body-link"
            data-test-transfer-sidebar-declined-reason-link
            >${this.intl.t('sdd-collections.creditor-identifier.germany.subtitle.how-to-text')}</a>`
      ),
      htmlSafe: true,
    }),
  };

  CREDITOR_IDENTIFIER_INPUT_LABEL_BY_COUNTRY = {
    FR: this.intl.t('sdd-collections.creditor-identifier.france.form.creditor-identifier.label'),
    IT: this.intl.t('sdd-collections.creditor-identifier.italy.form.creditor-identifier.label'),
    ES: this.intl.t('sdd-collections.creditor-identifier.spain.form.creditor-identifier.label'),
    DE: this.intl.t('sdd-collections.creditor-identifier.germany.form.creditor-identifier.label'),
  };

  CREDITOR_IDENTIFIER_INPUT_MESSAGE_BY_COUNTRY = {
    FR: this.intl.t('sdd-collections.creditor-identifier.france.form.creditor-identifier.info'),
    DE: this.intl.t('sdd-collections.creditor-identifier.germany.form.creditor-identifier.info'),
  };

  CREDITOR_IDENTIFIER_INPUT_PLACEHOLDER_BY_COUNTRY = {
    FR: this.intl.t(
      'sdd-collections.creditor-identifier.france.form.creditor-identifier.placeholder'
    ),
    DE: this.intl.t(
      'sdd-collections.creditor-identifier.germany.form.creditor-identifier.placeholder'
    ),
  };

  CREDITOR_IDENTIFIER_INVALID_ERROR_MESSAGE_BY_COUNTRY = {
    FR: this.intl.t('sdd-collections.creditor-identifier.france.form.creditor-identifier.error'),
    DE: this.intl.t('sdd-collections.creditor-identifier.germany.form.creditor-identifier.error'),
  };

  FIELD_ERROR_TYPES = {
    CID_INVALID: 'cid_invalid',
    CID_REQUIRED: 'cid_required',
    TOS_NOT_ACCEPTED: 'tos_not_accepted',
  };

  constructor() {
    super(...arguments);
    // by default the user will be able to input the Creditor Identifier
    this.activation.cidRequested = false;
    // if the Creditor Identifier is already set, we want to display it
    this.creditorIdentifier = this.activation.cid;
  }

  get isFrenchOrganization() {
    return this.organizationManager.organization.legalCountry === 'FR';
  }

  get isItalianOrSpanishOrganization() {
    let legalCountry = this.organizationManager.organization.legalCountry;
    return legalCountry === 'IT' || legalCountry === 'ES';
  }

  get subtitle() {
    let legalCountry = this.organizationManager.organization.legalCountry;
    return this.SUBTITLE_BY_LEGAL_COUNTRY[legalCountry];
  }

  get creditorIdentifierInputLabel() {
    let legalCountry = this.organizationManager.organization.legalCountry;
    return this.CREDITOR_IDENTIFIER_INPUT_LABEL_BY_COUNTRY[legalCountry];
  }

  get hasCreditorIdentifierErrors() {
    let hasErrors = this.activation.errors.get(CREDITOR_IDENTIFIER_VARIABLE_NAME)?.length > 0;
    return hasErrors;
  }

  get creditorIdentifierErrorType() {
    if (!this.hasCreditorIdentifierErrors) return undefined;

    let errorType = this.activation.errors
      .get(CREDITOR_IDENTIFIER_VARIABLE_NAME)
      .some(e => e.message === this.FIELD_ERROR_TYPES.CID_INVALID)
      ? this.FIELD_ERROR_TYPES.CID_INVALID
      : this.FIELD_ERROR_TYPES.CID_REQUIRED;

    return errorType;
  }

  get haveTermsOfServiceErrors() {
    return this.activation.errors.has(TERMS_OF_SERVICE_VARIABLE_NAME);
  }

  get creditorIdentifierInputMessageLevel() {
    return this.hasCreditorIdentifierErrors ? 'error' : undefined;
  }

  get creditorIdentifierInputMessage() {
    let legalCountry = this.organizationManager.organization.legalCountry;

    //IT and ES orgas dont have this input
    if (legalCountry === 'IT' || legalCountry === 'ES') {
      return undefined;
    }

    //we compute the message translation based on error type
    let errorType = this.creditorIdentifierErrorType;

    if (errorType === this.FIELD_ERROR_TYPES.CID_INVALID) {
      return this.CREDITOR_IDENTIFIER_INVALID_ERROR_MESSAGE_BY_COUNTRY[legalCountry];
    } else if (errorType === this.FIELD_ERROR_TYPES.CID_REQUIRED) {
      return this.intl.t('validations.errors.blank');
    }

    //if there are no errors we return the helper message
    return this.CREDITOR_IDENTIFIER_INPUT_MESSAGE_BY_COUNTRY[legalCountry];
  }

  get creditorIdentifierInputPlaceholder() {
    let legalCountry = this.organizationManager.organization.legalCountry;
    if (legalCountry === 'IT' || legalCountry === 'ES') {
      return undefined;
    }

    return this.CREDITOR_IDENTIFIER_INPUT_PLACEHOLDER_BY_COUNTRY[legalCountry];
  }

  get inputFieldDisabled() {
    return this.creditorIdentifierSelection !== 'input';
  }

  @action
  onCIDSelectionChange(choice) {
    this.creditorIdentifierSelection = choice;

    if (choice === 'input') {
      this.activation.cidRequested = false;
    } else if (choice === 'request') {
      this.activation.cidRequested = true;

      if (this.activation.errors.has(CREDITOR_IDENTIFIER_VARIABLE_NAME)) {
        this.activation.errors.remove(CREDITOR_IDENTIFIER_VARIABLE_NAME);
      }
    }
  }

  @action
  onCreditorIdentifierUpdate(creditorIdentifier) {
    if (this.activation.errors.has(CREDITOR_IDENTIFIER_VARIABLE_NAME)) {
      this.activation.errors.remove(CREDITOR_IDENTIFIER_VARIABLE_NAME);
    }

    if (creditorIdentifier === '') {
      this.activation.errors.add(
        CREDITOR_IDENTIFIER_VARIABLE_NAME,
        this.FIELD_ERROR_TYPES.CID_REQUIRED
      );
      return;
    }

    this.activation.cid = creditorIdentifier;
  }

  @action
  toggleTermsOfService() {
    if (this.activation.errors.has(TERMS_OF_SERVICE_VARIABLE_NAME)) {
      this.activation.errors.remove(TERMS_OF_SERVICE_VARIABLE_NAME);
    }

    this.activation.termsOfServiceAccepted = !this.activation.termsOfServiceAccepted;

    if (!this.activation.termsOfServiceAccepted) {
      this.activation.errors.add(
        TERMS_OF_SERVICE_VARIABLE_NAME,
        this.FIELD_ERROR_TYPES.TOS_NOT_ACCEPTED
      );
    }
  }

  openAbortModalTask = dropTask(async () => {
    let modal;
    try {
      modal = this.modals.open('popup/confirmation', {
        title: this.intl.t('sdd-collections.cancel-activation-flow.title'),
        description: this.intl.t('sdd-collections.cancel-activation-flow.subtitle'),
        cancel: this.intl.t('btn.back'),
        confirm: this.intl.t('btn.leave'),
        confirmTask: this.abortTask,
      });
      await modal;
    } finally {
      modal.close();
    }
  });

  abortTask = dropTask(async () => {
    await this.router.transitionTo('direct-debit-collections');
  });

  handleActivateTask = dropTask(async () => {
    this.segment.track('incoming-direct-debits-activation_submit-creditor-id_clicked');

    let isCreditorIdentifierEmpty =
      !this.activation.cid && this.creditorIdentifierSelection === 'input';

    if (isCreditorIdentifierEmpty) {
      this.activation.errors.add(
        CREDITOR_IDENTIFIER_VARIABLE_NAME,
        this.FIELD_ERROR_TYPES.CID_REQUIRED
      );
    }

    if (!this.activation.termsOfServiceAccepted) {
      this.activation.errors.add(
        TERMS_OF_SERVICE_VARIABLE_NAME,
        this.FIELD_ERROR_TYPES.TOS_NOT_ACCEPTED
      );
    }

    if (isCreditorIdentifierEmpty || !this.activation.termsOfServiceAccepted) {
      return;
    }

    try {
      await this.activation.activate();
      this.args.transitionToNext();
    } catch (error) {
      if (error.status === 422) {
        //422 cid_invalid is expected
        if (
          error.errors &&
          error.errors.filter(e => e.code === this.FIELD_ERROR_TYPES.CID_INVALID)?.length > 0
        ) {
          this.activation.errors.add(
            CREDITOR_IDENTIFIER_VARIABLE_NAME,
            this.FIELD_ERROR_TYPES.CID_INVALID
          );
        } else {
          //all other 422 errors we trigger a toast message and send to sentry
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
          this.sentry.captureException(
            new Error(`SDD Collection Activation failed with status ${error.status}`, {
              cause: error,
            })
          );
        }
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      }
    }
  });
}
