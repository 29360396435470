/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { PAY_LATER_FLOW_ORIGIN } from 'qonto/constants/financing';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
import { PromotionalBanner } from 'qonto/react/components/transfers/international-out/promotional-banner';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { isUserOfInterest } from 'qonto/utils/international-out/user';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class FlowsTransfersSepaNewDetailsComponent extends Component {
  promotionalBanner = PromotionalBanner;

  @service abilities;
  @service internationalOutManager;
  @service modals;
  @service segment;

  @tracked isValidationEnabled = false;

  constructor() {
    super(...arguments);

    let { context } = this.args;

    if (
      variation('feature--boolean-international-out-promotional-campaign') &&
      typeof context.isEligibleForInternationalOutPromotion !== 'boolean' &&
      !context.isInternationalOutPromotionalBannerDismissed
    ) {
      this.checkInternationalOutPromotionalEligibilityTask.perform().catch(ignoreCancelation);
    }
  }

  @action
  trackPromotionalBannerVisibility() {
    this.segment.track(EVENTS.VOUCHER_BANNER_DISPLAYED);
  }

  @action
  handleClick() {
    this.segment.track(EVENTS.VOUCHER_BANNER_CLICKED);
    this.args.transitionToFlow({
      flowName: 'international-out',
      stepId: 'quote',
      queryParams: {
        voucher: true,
      },
    });
  }

  get isPromotionalBannerDisplayed() {
    let { isEligibleForInternationalOutPromotion, isInternationalOutPromotionalBannerDismissed } =
      this.args.context;
    return (
      !isInternationalOutPromotionalBannerDismissed &&
      isEligibleForInternationalOutPromotion &&
      isUserOfInterest({ bic: this.beneficiary.get('bic') })
    );
  }

  get beneficiary() {
    return this.sepaTransfer.get('beneficiary');
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  get showPayLaterToggle() {
    if (!variation('feature--boolean-pay-later-modular-flow-pay-by-beneficiary')) return false;

    return (
      this.abilities.can('view pay later toggle in financing') &&
      this.args.context.isPayLaterEligible
    );
  }

  get origin() {
    return PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY;
  }

  get isPayLaterToggleDisabled() {
    let organization = this.sepaTransfer.get('organization');

    return organization?.get('hasPreventPayLaterToggle') === true;
  }

  onClickNextTask = dropTask(async sepaTransfer => {
    await sepaTransfer.validate();

    this.isValidationEnabled = true;

    let { reference, bankAccount, amount, scheduledDate } = sepaTransfer.validations.attrs;
    let { context, transitionToNext } = this.args;
    let { origin, isPayLater } = context;

    if (reference.isInvalid || bankAccount.isInvalid || scheduledDate.isInvalid) {
      this._scrollToErrorField();
      return;
    }

    if (amount.isInvalid) {
      this._scrollToErrorField();

      let shouldShowLowBalanceModal =
        this.showPayLaterToggle && !isPayLater && !this.isPayLaterToggleDisabled;

      if (shouldShowLowBalanceModal) {
        this.handleLowBalanceTask.perform().catch(ignoreCancelation);
      }

      return;
    }

    this.segment.track('transfer-sepa_amount_submitted', {
      ...(origin && { origin }),
    });

    if (variation('feature--boolean-pay-later-modular-flow-pay-by-beneficiary') && isPayLater) {
      return this.args.transitionToFlow({
        flowName: 'pay-later-transfer',
        stepId: 'invoice-upload',
        queryParams: {
          transferId: sepaTransfer.idempotencyKey,
          origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
        },
      });
    }

    transitionToNext();
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  @action
  togglePayLater() {
    let { context } = this.args;

    context.isPayLater = !context.isPayLater;

    if (context.isPayLater) {
      this.isValidationEnabled = false;
    }

    this.sepaTransfer.operationType = context.isPayLater
      ? OPERATION_TYPES.PAY_LATER
      : OPERATION_TYPES.SCHEDULED;

    this.segment.track('pay_later-toggle_switch', {
      state: context.isPayLater ? 'on' : 'off',
    });
  }

  handleLowBalanceTask = dropTask(async () => {
    let isLowBalanceScenario =
      Number(this.sepaTransfer.amount) > Number(this.sepaTransfer.bankAccount.authorizedBalance);

    if (!isLowBalanceScenario) return;

    await this.modals.open('transfers/sepa/pay-later/low-balance-modal', {
      confirmTask: this.redirectToPayLaterFlowTask,
    });
  });

  redirectToPayLaterFlowTask = dropTask(async close => {
    this.segment.track('pay_later-lower_balance_continue_clicked', {
      origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
    });

    await close();

    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY,
        transferId: this.sepaTransfer.idempotencyKey,
      },
    });
  });

  checkInternationalOutPromotionalEligibilityTask = dropTask(async () => {
    this.args.context.isEligibleForInternationalOutPromotion =
      await this.internationalOutManager.isEligibleForPromotion();
  });
}
