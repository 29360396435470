export default {
  "container": "UB",
  "disabled-option": "UE",
  "content": "Ud",
  "spinner": "UI",
  "subtitle": "Ut",
  "details-table": "UM",
  "caption": "UP",
  "tooltip-icon": "Ul",
  "tooltip-icon-right": "UX",
  "card-monthly-cost": "UC",
  "card-monthly-discounted-cost": "Uk",
  "strikethrough": "UJ",
  "purple-text": "Us",
  "budget-label": "UF",
  "card-cost-label": "Ug",
  "card-billing-account": "UD"
};
