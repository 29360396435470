export default {
  "sidebar": "csp",
  "top-section": "csx",
  "title": "csw",
  "primary-action": "csN",
  "edit-icon": "csv",
  "close-icon": "cso",
  "box-wrapper": "csn",
  "bottom-action": "csi",
  "related-invoices": "csA",
  "related-invoices-title": "csY mb-16 caption-bold",
  "box": "csh",
  "box-element": "csf",
  "cancel-text": "csK",
  "box-header": "csG",
  "row": "csr",
  "greyed-out": "csb",
  "struck-through": "csy",
  "share-mandate-link": "csU"
};
