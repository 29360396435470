export default {
  "container": "GD",
  "container-content": "GV",
  "content": "GL",
  "underline": "GT",
  "inline-content": "Gz",
  "payment-rate": "Ga",
  "payment-subtitle": "GH",
  "grey-text": "GO",
  "earnings-header": "Gm",
  "earnings-tooltip": "Gp"
};
