export default {
  "form": "Ap",
  "fieldset": "Ax",
  "form-body": "Aw",
  "conditional-select": "AN",
  "conditional-text": "Av",
  "group": "Ao",
  "add-filter-button": "An btn btn--tertiary",
  "footer": "Ai",
  "btn-icon": "AA",
  "add-icon": "AY",
  "cancel-button": "Ah btn",
  "form-header": "Af",
  "align-start": "AK",
  "align-right": "AG",
  "button-wrapper": "Ar",
  "wrapper": "Ab",
  "label": "Ay body-2",
  "input-wrapper": "AU",
  "input": "Aj input-field body-2",
  "error": "AW",
  "error-message": "Au"
};
