/* import __COLOCATED_TEMPLATE__ from './check-field.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

import {
  formatCheckCMC7,
  formatCheckRLMC,
  handleFormatCheckCMC7,
  handleFormatCheckRLMC,
} from 'qonto/utils/format-input';

export default class CheckField extends Component {
  rlmcInputId = `${guidFor(this)}-rlmc-input`;

  get errorMessage() {
    return this.args.cmc7Error || this.args.rlmcError;
  }

  get cmc7() {
    return formatCheckCMC7(this.args.cmc7);
  }

  get rlmc() {
    return formatCheckRLMC(this.args.rlmc);
  }

  @action
  handleOnCmc7Input(event) {
    let { value } = event.target;
    let rawCmc7 = value.replace(/\D/g, '');

    // if CMC7 is complete, move focus to RLMC input
    if (rawCmc7.length >= 31) {
      document.getElementById(this.rlmcInputId).focus();
    }

    handleFormatCheckCMC7(event.target);

    this.args.updateCmc7(rawCmc7);
  }

  @action
  handleOnRlmcInput(event) {
    let { target } = event;
    handleFormatCheckRLMC(target);

    this.args.updateRlmc(target.value.replace(/\D/g, ''));
  }
}
