export default {
  "integration-item": "clU",
  "icon-container": "clj",
  "content": "clW",
  "title-container": "clu",
  "new-badge": "cXS",
  "title": "cXc",
  "description": "cXq",
  "no-attributes": "cXZ",
  "connect-cta": "cXR"
};
