export default {
  "color-option": "cMR",
  "color-dot": "cMQ",
  "any": "cMe",
  "red": "cMB",
  "orange": "cME",
  "yellow": "cMd",
  "green": "cMI",
  "blue": "cMt",
  "purple": "cMM",
  "pink": "cMP",
  "black": "cMl",
  "grey": "cMX",
  "brown": "cMC",
  "color-name": "cMk"
};
