export default {
  "content": "cEN",
  "error-message": "cEv",
  "wrapper": "cEo",
  "banner": "cEn",
  "banner-content": "cEi",
  "banner-text": "cEA",
  "banner-image": "cEY",
  "banner-title": "cEh",
  "banner-description": "cEf body-2",
  "link": "cEK",
  "plans-comparison": "cEG",
  "plan-box": "cEr",
  "arrow": "cEb",
  "arrow-icon": "cEy",
  "link-downgrade": "cEU",
  "stroke-text": "cEj"
};
