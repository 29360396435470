export default {
  "body": "cVR",
  "members": "cVQ",
  "members-results": "cVe",
  "members-list": "cVB",
  "members-title": "cVE caption-bold",
  "member": "cVd",
  "empty-illustration": "cVI",
  "empty-title": "cVt title-3",
  "empty-description": "cVM body-2",
  "invitable-members": "cVP",
  "details": "cVl",
  "pagination-footer": "cVX"
};
