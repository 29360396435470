export default {
  "wrapper": "ctA input-field mb-4",
  "error": "ctY",
  "inactive": "cth",
  "hidden-value": "ctf",
  "percentage-sign": "ctK",
  "black": "ctG",
  "input-field": "ctr",
  "disabled": "ctb"
};
