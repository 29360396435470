/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, timeout, waitForQueue } from 'ember-concurrency';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export const DEFAULT_BANKS_PER_PAGE = 100;
export const MAX_POPULAR_BANKS = 20;

export default class FlowsAccountsExternalImportSelectComponent extends Component {
  @service organizationManager;
  @service networkManager;
  @service segment;
  @service sentry;

  @tracked allBanks;
  @tracked mostPopularBanks;
  @tracked searchQuery;
  @tracked scrollableElement = null;

  page = 1;
  hasMorePages = false;

  constructor() {
    super(...arguments);

    next(() => {
      // If we already have a bank in the context, and we are entering the flow from Connect, we
      // don’t need to do anything in this step, we just transition to the next to persist the
      // context and have it available when we come back from the webview
      let { context, transitionToNext } = this.args;
      let isComingFromConnectHub = context.origin?.includes('connect-hub');
      let isBankSetInContext =
        Boolean(context.bank_id) && Boolean(context.bank_name) && Boolean(context.bank_logo);

      if (isBankSetInContext && isComingFromConnectHub) {
        transitionToNext();
      } else {
        this.loadInitialData();
        this.setScrollableElement();
      }
    });
  }

  async setScrollableElement() {
    await waitForQueue('afterRender');

    this.scrollableElement = document.querySelector('[data-scrollable]');
  }

  get showEmptyState() {
    return this.searchQuery && !this.allBanks?.length && !this.searchTask.isRunning;
  }

  loadInitialData() {
    this.initialLoadTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.handleError(error);
      });
  }

  @action
  loadMore() {
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.loadMoreTask.perform();
  }

  @action
  onConnectorSelect(bank) {
    let { context, transitionToNext } = this.args;

    context.bank_id = bank.id;
    context.bank_name = bank.name;
    context.bank_logo = bank.logo.medium;

    this.segment.track('accounts_external-search_result_clicked', { bank: bank.name });

    transitionToNext();
  }

  @action triggerSearchTask(searchQuery) {
    this.searchQuery = searchQuery;
    return this.searchTask
      .perform(this.searchQuery)
      .catch(ignoreCancelation)
      .catch(error => {
        this.handleError(error);
      });
  }

  searchTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);

    this.searchQuery = query;
    this.page = 1;

    let { banks, meta } = await this.fetchBanks({
      q: query,
      page: this.page,
      per_page: DEFAULT_BANKS_PER_PAGE,
    });

    if (banks.length === 0) {
      this.segment.track('accounts_external-search_result_not_found', { query });
    }

    this.hasMorePages = Boolean(meta.next_page);
    this.allBanks = banks;
  });

  initialLoadTask = dropTask(async () => {
    let [allBanksResponse, mostPopularBanksResponse] = await Promise.all([
      this.fetchBanks({
        page: this.page,
        per_page: DEFAULT_BANKS_PER_PAGE,
      }),
      this.fetchBanks({
        popular: true,
        country: this.organizationManager.organization.legalCountry,
        page: this.page,
        per_page: MAX_POPULAR_BANKS,
      }),
    ]);

    this.hasMorePages = Boolean(allBanksResponse.meta.next_page);
    this.allBanks = allBanksResponse.banks;
    this.mostPopularBanks = mostPopularBanksResponse.banks;
  });

  loadMoreTask = dropTask(async () => {
    if (!this.hasMorePages) return;

    this.page += 1;

    let { banks, meta } = await this.fetchBanks({
      q: this.searchQuery,
      page: this.page,
      per_page: DEFAULT_BANKS_PER_PAGE,
    });

    this.hasMorePages = Boolean(meta.next_page);
    this.allBanks = [...this.allBanks, ...banks];
  });

  @action
  triggerDataReload() {
    return this.searchQuery ? this.triggerSearchTask(this.searchQuery) : this.loadInitialData();
  }

  async fetchBanks(params = {}) {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${apiNamespace}/account_aggregation/banks`,
      {
        method: 'GET',
        data: params,
      }
    );

    return response;
  }

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
