/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { importAsset } from 'qonto/helpers/import-asset';

const VIDEO_PATHS = {
  de: '/videos/sdd-collection/value-prop-de.webm',
  en: '/videos/sdd-collection/value-prop-en.webm',
  es: '/videos/sdd-collection/value-prop-es.webm',
  fr: '/videos/sdd-collection/value-prop-fr.webm',
  it: '/videos/sdd-collection/value-prop-it.webm',
  pt: '/videos/sdd-collection/value-prop-pt.webm',
};

const IMAGE_PATHS = {
  de: '/illustrations/sdd-collection/value-prop-de.webp',
  en: '/illustrations/sdd-collection/value-prop-en.webp',
  es: '/illustrations/sdd-collection/value-prop-es.webp',
  fr: '/illustrations/sdd-collection/value-prop-fr.webp',
  it: '/illustrations/sdd-collection/value-prop-it.webp',
  pt: '/illustrations/sdd-collection/value-prop-pt.webp',
};

export default class IntroComponent extends Component {
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service toastFlashMessages;
  @service segment;
  @service subscriptionManager;
  @service zendeskLocalization;
  @reads('args.context.activation') activation;

  @tracked shouldLoadSkeleton = false;

  get videoPath() {
    return importAsset([VIDEO_PATHS[this.localeManager.locale]]);
  }

  get imagePath() {
    return importAsset([IMAGE_PATHS[this.localeManager.locale]]);
  }

  get collectionLimit() {
    return this.intl.formatNumber(this.activation.collectionLimit.value, {
      currency: this.activation.collectionLimit.currency,
      style: 'currency',
      minimumFractionDigits: 0,
    });
  }

  get guardingDepositPercentage() {
    return this.intl.formatNumber(this.activation.guardingDepositPercentage, {
      style: 'percent',
    });
  }

  get pricingFeeInformation() {
    let pricingFee = this.intl.formatNumber(
      this.subscriptionManager.options.directDebitCollectionSend.value,
      { style: 'currency', currency: 'EUR' }
    );

    let planName = this.subscriptionManager.currentPricePlan.localName;

    let feesLink = htmlSafe(
      `<a
          href=${this.intl.t('sdd-collections.selling-proposition.selling-point-3.url', {
            faqUrl: this.zendeskLocalization.getLocalizedArticle(9235605),
          })}
          target="_blank"
          rel="noopener noreferrer"
          class="body-link"
        >${this.intl.t('sdd-collections.selling-proposition.selling-point-3.link-text')}</a>`
    );

    return {
      pricingFee,
      planName,
      feesLink,
    };
  }

  get depositSellingPoint() {
    if (this.activation.hasGuarding) {
      return {
        title: this.intl.t(
          'sdd-collections.selling-proposition.selling-point-2.guarding.title-new'
        ),
        content: htmlSafe(
          this.intl.t('sdd-collections.selling-proposition.selling-point-2.guarding.content-new', {
            guardingPeriodPercentage: this.guardingDepositPercentage,
          })
        ),
      };
    } else {
      return {
        title: this.intl.t('sdd-collections.selling-proposition.selling-point-2.no-guarding.title'),
        content: this.intl.t(
          'sdd-collections.selling-proposition.selling-point-2.no-guarding.content'
        ),
      };
    }
  }

  handleSetupTask = dropTask(async () => {
    this.segment.track('incoming-direct-debits-activation_usp_clicked');

    try {
      let { organization } = this.organizationManager;
      let isCountryItaly = organization.legalCountry === 'IT';
      let isCountrySpain = organization.legalCountry === 'ES';

      if (isCountryItaly || isCountrySpain) {
        this.shouldLoadSkeleton = true;

        await this.activation.fetchCid();
      }
    } catch {
      this.shouldLoadSkeleton = false;
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      return;
    }

    this.args.transitionToNext();
  });
}
