export default {
  "upsell-cta": "cZN",
  "discover-badge": "cZv",
  "role-selection-item": "cZo",
  "unavailable": "cZn",
  "role-headline": "cZi",
  "illustration": "cZA",
  "role-description": "cZY",
  "description-item": "cZh",
  "description-icon": "cZf",
  "description-text": "cZK",
  "current-role": "cZG"
};
