export default {
  "mandate-list-item": "cgT",
  "selected": "cgz",
  "status-avatar": "cga",
  "infos": "cgH",
  "info-title": "cgO body-1",
  "info-title-suspended": "cgm",
  "info-title-status-suspended": "cgp",
  "info-mandate-count": "cgx body-2"
};
