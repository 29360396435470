export default {
  "card": "cFr",
  "focus": "cFb",
  "title-format": "cFy",
  "header": "cFU",
  "headings": "cFj",
  "title": "cFW title-4",
  "main": "cFu title-3",
  "footer": "cgS"
};
