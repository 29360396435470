export default {
  "wrapper": "cMv",
  "close-button": "cMo",
  "content-wrapper": "cMn",
  "main": "cMi",
  "main-content": "cMA",
  "description": "cMY",
  "mt-26": "cMh",
  "mt-62": "cMf",
  "user-prompt": "cMK",
  "user-prompt-inappropriate-warning": "cMG",
  "logo-preview": "cMr",
  "static-loader": "cMb",
  "loading-state-illustration": "cMy",
  "sticky-panel": "cMU"
};
