export default {
  "container": "YE",
  "header-wrapper": "Yd mb-16",
  "header": "YI",
  "logo": "Yt mr-16",
  "title": "YM title-4",
  "description": "YP body-2",
  "conditions": "Yl",
  "label": "YX caption mb-4",
  "tags": "YC"
};
