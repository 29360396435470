/* import __COLOCATED_TEMPLATE__ from './desktop-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';
import { ErrorInfo } from 'qonto/utils/error-info';
import { addDocumentFileToModel, removeDocumentFileFromModel } from 'qonto/utils/handle-documents';

const USER_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'gender',
  'birthdate',
  'birthCity',
  'birthCountry',
];

export const INVITE_FIELDS = ['proofOfIdentity', 'nationality'];

export default class InvitationsNewUserInfoDesktopFormComponent extends Component {
  @service intl;
  @service modals;
  @service store;
  @service segment;
  @service sentry;

  @reads('args.user.didValidate') enableValidations;
  @reads('args.invite.didValidate') enableInviteValidations;

  get documentsDropzoneText() {
    let { intl } = this;

    return htmlSafe(
      intl.t('join-team.about-you.add-poi.drop-zone.label', {
        maxSize: formatFileSize(intl, TEMPLATE_VALUES.avatarMaxSize),
      })
    );
  }

  get firstName() {
    return this.args.user.firstName || this.args.invite.firstName;
  }

  get lastName() {
    return this.args.user.lastName || this.args.invite.lastName;
  }

  get isItalianOrganization() {
    return this.args.invite.organizationLegalCountry === 'IT';
  }

  get userHasBirthdate() {
    let { user } = this.args;
    // if user already has a birthday disable the field.
    return user.birthdate && !user.changedAttributes()['birthdate'];
  }

  get birthdateErrorMessage() {
    let { user } = this.args;
    return this.enableValidations ? user.validations.attrs.birthdate.message : null;
  }

  get birthCityErrorMessage() {
    let { user } = this.args;
    return this.enableValidations || this.enableBirthCityValidations
      ? user.validations.attrs.birthCity.message
      : null;
  }

  submitUserTask = dropTask(async (invite, user) => {
    this.args.invite.set('didValidate', false);
    this.args.user.set('didValidate', false);

    let { validations: userValidation } = await user.validate({ on: USER_FIELDS });
    let { validations: inviteValidation } = await invite.validate({ on: INVITE_FIELDS });

    this.args.invite.set('didValidate', true);
    this.args.user.set('didValidate', true);

    if (userValidation.isValid && inviteValidation.isValid) {
      try {
        await this.args.submitUser(invite, user);
        this.segment.track('join_team_personal_information_filled', {
          role: invite.role,
          invitation_id: invite.id,
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
  });

  @action
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
    });
  }

  @action
  handleAddDocument(model, docType, file) {
    addDocumentFileToModel(this.store, model, docType, file);
  }

  @action
  handleRemoveFileFromDocument(model, docType, file) {
    removeDocumentFileFromModel(this.store, model, docType, file);
  }

  @action
  updateBirthdate(value) {
    // As we now use DateFieldV2, the value we receive from the component
    // is formatted as a YYYY-MM-DD string date
    if (value) {
      this.args.user.set('birthdate', value);
    }
  }
}
