export default {
  "voucher-wrapper": "cdB",
  "left-side": "cdE",
  "left-side__wrapper": "cdd",
  "voucher-illustration": "cdI",
  "left-side__wrapper-title": "cdt",
  "voucher-list": "cdM",
  "left-side__wrapper-details": "cdP",
  "right-side": "cdl",
  "multi-accounts": "cdX",
  "right-side__wrapper": "cdC"
};
