/* import __COLOCATED_TEMPLATE__ from './horizontal-scroller.hbs'; */
import Component from '@glimmer/component';

export default class HorizontalScrollerComponent extends Component {
  get columnsElm() {
    return document.querySelector('[data-horizontal-scroller-slider-element]');
  }

  isDown = false;
  startX;
  scrollTop;

  startSlider = e => {
    this.isDown = true;
    this.startX = e.pageX - this.columnsElm.offsetLeft;
    this.scrollTop = this.columnsElm.scrollTop;
  };

  stopSlider = () => {
    this.isDown = false;
  };

  moveSlider = e => {
    if (!this.isDown) return;
    e.preventDefault();
    let delta = e.pageX - this.startX;
    this.columnsElm.scrollTop = this.scrollTop - delta;
  };
}
