export default {
  "cell": "Yf",
  "empty": "YK",
  "supplier": "YG",
  "supplier-content": "Yr",
  "declined": "Yb",
  "total-repayment": "Yy body-1",
  "amount": "YU",
  "supplier-avatar": "Yj mr-16",
  "financing-status": "YW body-2",
  "installments": "Yu body-2",
  "overflow-hidden": "hS",
  "ellipsis": "hc",
  "completed-on": "hq",
  "financed-amount": "hZ body-2"
};
