export default {
  "checks": "jL",
  "content": "jT",
  "form": "jz",
  "title": "ja",
  "description": "jH",
  "wrapper": "jO",
  "aside": "jm",
  "amount-field": "jp"
};
