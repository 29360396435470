export default {
  "cell": "hP",
  "empty": "hl",
  "supplier": "hX",
  "supplier-content": "hC",
  "supplier-avatar": "hk mr-16",
  "financing-status": "hJ body-2",
  "installments": "hs body-2",
  "overflow-hidden": "hF",
  "ellipsis": "hg",
  "next-installment-date": "hD",
  "next-installment-amount": "hV body-2",
  "amount": "hL",
  "disclaimer": "hT",
  "disclaimer-icon": "hz",
  "warning": "ha",
  "error": "hH",
  "total-repayment": "hO body-1"
};
