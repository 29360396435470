/* import __COLOCATED_TEMPLATE__ from './modelo-select.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class NrcModeloSelectComponent extends Component {
  @service networkManager;
  @service intl;
  @service segment;
  @service sentry;
  @service store;

  constructor(...args) {
    super(...args);
    this.getModelosTask.perform().catch(ignoreCancelation);

    this.args.context.nrcPayment = this.store.createRecord('nrc-payment');
  }

  @tracked filteredModelos = [];
  @tracked searchQuery;
  @tracked isError = false;

  getModelosTask = dropTask(async () => {
    this.isError = false;

    try {
      let response = await this.networkManager.request(`${apiBaseURL}/v1/nrc/modelos`, {
        method: 'GET',
      });

      //we add a searchable string to the modelo objects
      let modelosWithSearchString = response.modelos.map(item => ({
        ...item,
        search_string: this._normalizeSearchString(
          `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${this._formatTaxCode(item.tax_code)} ${item.tax_description}`
        ),
      }));

      this.args.context.modelos = modelosWithSearchString;
      this.filteredModelos = modelosWithSearchString;
      this.segment.track('nrc_form-list_loaded');
    } catch (error) {
      this.isError = true;

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get showErrorState() {
    return this.isError;
  }

  get showEmptySearchResults() {
    return !this.isError && this.filteredModelos.length === 0 && this.searchQuery;
  }

  @action
  selectorTitle(option) {
    return `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${this._formatTaxCode(option.tax_code)}`;
  }

  @action
  selectorToolTip(option) {
    return `${this.intl.t('aeat.pay.form-selection.card-title-prefix')} ${this._formatTaxCode(option.tax_code)} - ${option.tax_description}`;
  }

  @action
  updateModeloList(query) {
    this.filteredModelos = this.args.context.modelos.filter(modelo =>
      modelo.search_string.includes(this._normalizeSearchString(query))
    );
    this.searchQuery = query;
  }

  @action
  selectModelo(option) {
    this.segment.track('nrc_form-list_selected');

    let { context, transitionToNext } = this.args;
    context.selectedModelo = option;
    context.nrcPayment.taxCode = option.tax_code;

    transitionToNext();
  }

  _normalizeSearchString(string) {
    return string
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  _formatTaxCode(taxCode) {
    return parseInt(taxCode, 10).toString();
  }

  button = Button;
  lottiePlayer = LottiePlayer;
}
