/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { CODES } from 'qonto/constants/empty-states/system';
import { BASE_INSIGHTS } from 'qonto/constants/supplier-invoice';

export default class EmptyStatesSupplierInvoicesDummyDataComponent extends Component {
  sortBy = 'due_date:asc';
  noop() {}

  get enableAllTabs() {
    return [CODES.ES_U1, CODES.ES_T1].includes(this.args.emptyStateCode);
  }

  get BASE_INSIGHTS() {
    return BASE_INSIGHTS;
  }
}
