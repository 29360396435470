export default {
  "container": "fM",
  "company-details": "fP",
  "sideview": "fl",
  "form": "fX",
  "french-company-details-fieldset": "fC",
  "footer": "fk",
  "pdf-preview": "fJ",
  "de-layout": "fs",
  "de-pdf-preview": "fF",
  "german-preview": "fg"
};
