/* import __COLOCATED_TEMPLATE__ from './team-scope.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { ACCOUNT_STATUS } from 'qonto/constants/bank-account';
import { MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH } from 'qonto/constants/member/account-management';
import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class MemberInviteAccountManagementTeamScopeComponent extends Component {
  @service('intl') intl;
  @service organizationManager;
  @service sensitiveActions;
  @service segment;
  @service sentry;
  @service toastFlashMessages;
  @service abilities;

  @tracked bankAccountsError;

  get bankAccounts() {
    return this.organizationManager.organization.sortedAlphabeticallyInternalAccounts;
  }

  @cached
  get bankAccountsOptions() {
    return this.bankAccounts.map(account => ({
      key: account.id,
      value: account.name,
      authorizedBalance: account.authorizedBalance,
      balanceCurrency: account.balanceCurrency,
      closed: account.status === ACCOUNT_STATUS.CLOSED,
    }));
  }

  get memberAllowedBankAccounts() {
    return this.bankAccountsOptions.filter(account =>
      this.memberAllowedBankAccountsIds?.includes(account.key)
    );
  }

  get memberAllowedBankAccountsIds() {
    return this.member.allowedBankAccountsIds;
  }

  get isSearchEnabled() {
    return this.bankAccounts.length > MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH;
  }

  get member() {
    return this.args.context.member;
  }

  get contextCustomPermissions() {
    return this.member.customPermissions;
  }

  get isAdvancedManager() {
    return this.member.role === ROLES.ADVANCED_MANAGER;
  }

  get isEditingMember() {
    return this.args.context.isEditingMember;
  }

  get shouldShowRestrictedPermissions() {
    if (this.isEditingMember) {
      return this.abilities.can('access advanced manager role feature members');
    }

    return this.isAdvancedManager;
  }

  permissions = {
    transactions: {
      title: this.intl.t('invitation-flow.accounts.team-scope.transactions-access.title'),
      image: '/illustrations/permission/team-transactions.svg',
      checked: true, // This selector is checked by default as team scope managers are always allowed to access only team transactions
      disabled: true,
    },
    bookkeeping: {
      key: 'manage_bookkeepings',
      title: this.intl.t('invitation-flow.permissions.bookkeeping.title'),
      image: '/illustrations/permission/bookkeeping.svg',
      checked: this.contextCustomPermissions.groups?.manage_bookkeepings,
    },
    accounts: {
      allowedAccounts: this.memberAllowedBankAccounts,
    },
    balance: {
      key: 'access_bank_accounts_balance',
      title: this.intl.t('invitation-flow.permissions.team-scope.accounts.label'),
      checked: this.contextCustomPermissions.groups?.access_bank_accounts_balance,
      disabled: this.memberAllowedBankAccounts.length === 0,
    },
  };

  @action
  updatePermission(shortKey) {
    let localPermission = this.permissions[shortKey];
    set(localPermission, 'checked', !localPermission.checked);

    this.setCustomPermission(localPermission.key, localPermission.checked);
  }

  @action
  updateAllowedBankAccountIds(selectedAccounts) {
    this.resetError();
    let localPermission = this.permissions.accounts;
    let selectedAccountIds = selectedAccounts?.map(account => account.key);
    set(localPermission, 'allowedAccounts', selectedAccounts);

    this.member.allowedBankAccountsIds = selectedAccountIds ?? [];
    this.updateAccessBalanceToggleDisableState();
  }

  submitTask = dropTask(async () => {
    let { transitionToNext, stepList } = this.args;
    let [firstStep] = stepList;

    let hasActiveBankAccount = this.memberAllowedBankAccounts.some(account => !account.closed);

    if (
      !this.memberAllowedBankAccountsIds ||
      !this.memberAllowedBankAccountsIds.length ||
      !hasActiveBankAccount
    ) {
      this.bankAccountsError = this.intl.t(
        'invitation-flow.permissions.accounts.validation.active-bank-account'
      );
      return;
    }

    this.segment.track('team_invite_account-permissions_set');
    this.segment.track('team_invite_account-list_set', {
      no_of_accounts: this.memberAllowedBankAccountsIds.length,
    });

    if (!this.args.context.isEditingMember) {
      return transitionToNext();
    }

    if (!['account-management-team', 'account-management-organization'].includes(firstStep.id)) {
      return transitionToNext();
    }

    // If the first step is account-management-team or account-management-organization
    // it means that the user is in the account permissions flow, hence we need to save the task
    await this.sensitiveActions.runTask.perform(this.saveTask);
  });

  saveTask = dropTask(async () => {
    let { context, transitionToNext } = this.args;

    try {
      if (context.role?.key) {
        context.member.role = context.role.key;
      }

      await context.member.updateRole();
    } catch (error) {
      context.member.role = context.currentUserRole;

      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      return;
    }

    try {
      await context.member.updateAllowedBankAccounts(context.member.allowedBankAccountsIds);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      context.isMultipleStepsFlow = true;
      context.hasAllowedBankAccountsError = true;
    }

    if (!context.hasAllowedBankAccountsError) {
      this.segment.track('member_permissions-update_confirmed');
    }

    transitionToNext();
  });

  updateAccessBalanceToggleDisableState() {
    let {
      permissions: { balance },
    } = this;
    let isToggleDisabled = !this.memberAllowedBankAccountsIds.length;
    set(balance, 'disabled', isToggleDisabled);

    if (isToggleDisabled) {
      set(balance, 'checked', false);
      this.setCustomPermission(balance.key, balance.checked);
    }
  }

  setCustomPermission(key, value) {
    this.contextCustomPermissions.groups = {
      ...this.contextCustomPermissions.groups,
      [key]: value,
    };
  }

  resetError() {
    this.bankAccountsError = null;
  }
}
