export default {
  "container": "cdG",
  "content": "cdr",
  "sticky": "cdb",
  "divider": "cdy",
  "tooltip": "cdU",
  "detail": "cdj",
  "detail-value": "cdW",
  "color-secondary": "cdu",
  "beneficiary-information": "cIS",
  "beneficiary-source": "cIc",
  "currency": "cIq",
  "break-word": "cIZ",
  "disclaimers": "cIR",
  "sticky-panel": "cIQ"
};
