export default {
  "mandate-new-no-mandate": "ccD",
  "left-side": "ccV",
  "left-side__wrapper": "ccL",
  "left-side__wrapper-title": "ccT",
  "left-side__wrapper-details": "ccz",
  "left-side__wrapper-details-item": "cca",
  "right-side": "ccH",
  "multi-accounts": "ccO",
  "right-side__wrapper": "ccm",
  "right-side__wrapper-name": "ccp",
  "right-side__wrapper-address": "ccx",
  "right-side__wrapper-iban-dashed": "ccw",
  "right-side__wrapper-address-label": "ccN",
  "right-side__wrapper-iban-label": "ccv",
  "right-side__wrapper-bic-label": "cco",
  "right-side__wrapper-address-dashed": "ccn",
  "right-side__wrapper-bic-dashed": "cci",
  "dropdown-icon": "ccA",
  "dropdown": "ccY",
  "multi-accounts-illustration": "cch"
};
