/* import __COLOCATED_TEMPLATE__ from './clients.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { getDefaultLocale } from 'qonto/models/customer';

export default class FlowsDirectDebitCollectionsSubscriptionClientsComponent extends Component {
  @service store;
  @service segment;
  @service organizationManager;

  fetchClientsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    let clients = await this.store.query('customer', {
      adapterOptions: {
        context: 'sdd',
      },
      ...(searchQuery ? { filter: { name: searchQuery } } : {}),
    });

    if (!searchQuery && clients.length === 0) {
      this.args.context.newClient = this.store.createRecord('customer', {
        locale: getDefaultLocale(this.organizationManager.organization.legalCountry),
      });
      return this.args.pushForwardFlow('direct-debit-collection-client', 'add-client');
    }

    return clients;
  });

  @action
  onAddClient() {
    this.args.context.clientToEdit = null;
    this.args.context.newClient = this.store.createRecord('customer', {
      locale: getDefaultLocale(this.organizationManager.organization.legalCountry),
    });

    this.args.pushForwardFlow('direct-debit-collection-client', 'add-client');

    this.segment.track('incoming-direct-debit-flow_new-client_clicked');
  }

  @action
  onSelectClient(client) {
    this.args.context.clientToEdit = null;
    this.args.context.directDebitSubscription.client = client;

    if (!client.email) {
      this.args.context.clientToEdit = client;
      return this.args.pushForwardFlow('direct-debit-collection-client', 'edit-client');
    } else {
      this.args.transitionToNext();

      this.segment.track('incoming-direct-debit-flow_client_selected', {
        client_address: Boolean(client.address),
        client_selection: 'existing',
      });
    }
  }

  @action
  onEditClient(client) {
    this.args.context.clientToEdit = client;

    this.args.pushForwardFlow('direct-debit-collection-client', 'edit-client');

    this.segment.track('incoming-direct-debit-flow_edit-client_clicked');
  }
}
