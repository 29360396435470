/* import __COLOCATED_TEMPLATE__ from './add-beneficiary.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';

export default class FlowsTransfersSepaBeneficiaryAddBeneficiaryComponent extends Component {
  @service organizationManager;
  @service store;

  @tracked beneficiary;

  constructor() {
    super(...arguments);

    let { invoice } = this.args.context;

    this.beneficiary = this.store.createRecord('beneficiary', {
      ...(invoice?.supplierName && { name: invoice.supplierName }),
      ...(invoice?.iban && { iban: invoice.iban }),
      activityTag: 'other_expense',
      currency: CURRENCIES.default,
      organization: this.organizationManager.organization,
      vatRate: null,
    });
  }

  @action
  transitionToNext(newBeneficiary) {
    let { context, transitionToNext } = this.args;
    context.beneficiary = newBeneficiary;
    transitionToNext();
  }
}
