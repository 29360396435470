/* import __COLOCATED_TEMPLATE__ from './description.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';

export default class FlowsRequestCardsNoteComponent extends Component {
  @service intl;
  @service networkManager;
  @service segment;
  @service toastFlashMessages;

  textAreaField = TextAreaField;

  @tracked description = null;
  @tracked errorMessage = null;

  @action
  onUpdateDescription(value) {
    this.errorMessage = null;
    this.description = value;
  }

  submitTask = dropTask(async () => {
    if (!this.description || !this.description.trim()) {
      this.errorMessage = this.intl.t('dispute-flow.additional-info.form.error');
      this.description = null;
      return;
    }

    try {
      let { claimType, selectedTransactions, files } = this.args.context;

      let payload = {
        data: {
          type: 'claims',
          attributes: {
            type: claimType,
            description: this.description,
          },
          relationships: {
            transactions: {
              data: selectedTransactions.map(transaction => ({
                type: 'transactions',
                id: transaction.id,
              })),
            },
            documents: {
              data: files.flat().map(file => ({ id: file.id, type: 'documents' })),
            },
          },
        },
      };

      this.segment.track('cards-chargeback_submit-dispute-cta_clicked');

      await this.networkManager.request(`${apiBaseURL}/v1/claims`, {
        method: 'POST',
        data: payload,
      });

      this.args.transitionToNext();
    } catch {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });
}
