export default {
  "header-cell": "il caption-bold",
  "sortable": "iX",
  "animated": "iC",
  "fadein-item": "ik",
  "animated-cell": "iJ",
  "col1": "is",
  "header-content": "iF caption-bold",
  "active": "ig"
};
