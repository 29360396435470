/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class PreviewComponent extends Component {
  @service intl;
  @service organizationManager;

  get amount() {
    return this.args.context.nrcPayment.amount;
  }

  get receiptCode() {
    return this.args.context.nrcPayment.receiptCode;
  }

  get selectedModelo() {
    return `Modelo ${Number(this.args.context.selectedModelo.tax_code)}`;
  }

  get bankAccount() {
    return this.args.context.nrcPayment.bankAccount;
  }

  get legalName() {
    return this.args.context.nrcPayment.legalName;
  }

  get documentId() {
    return this.args.context.nrcPayment.documentId;
  }
}
