export default {
  "wrapper": "oi",
  "legend": "oA",
  "grid": "oY",
  "subtitle": "oh body-2",
  "fiscal-code": "of",
  "personal-data-name": "oK",
  "personal-data-birth": "oG",
  "personal-data": "or",
  "tax-residence": "ob",
  "payer-agent": "oy",
  "errors": "oU",
  "business": "oj",
  "address": "oW"
};
