/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsAccountsExternalImportConfirmationSuccessComponent extends Component {
  @service segment;

  @action
  restartFlow() {
    let { context, restartFlow } = this.args;

    this.segment.track('accounts_external-success_connect-another-account', {
      bank: context?.bank?.name || 'unknown',
    });

    restartFlow();
  }

  @action
  completeFlow() {
    let { context, completeFlow } = this.args;

    this.segment.track('accounts_external-success_go-to-connected-accounts', {
      bank: context?.bank?.name || 'unknown',
    });

    completeFlow();
  }
}
