export default {
  "container": "ckn",
  "form": "cki",
  "form-header": "ckA",
  "form-subtitle": "ckY",
  "form-input": "ckh",
  "left-input": "ckf",
  "right-input": "ckK",
  "label-fix": "ckG",
  "x-validated-input__header": "ckr",
  "form-dropzone": "ckb",
  "form-footer": "cky",
  "submit-cta": "ckU",
  "checklist": "ckj",
  "about-you-form": "ckW",
  "name-inputs": "cku",
  "birth-inputs": "cJS"
};
