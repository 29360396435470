export default {
  "container": "cLl",
  "close-button": "cLX",
  "content-wrapper": "cLC",
  "main": "cLk",
  "description": "cLJ",
  "calculation-item": "cLs",
  "total-km": "cLF",
  "calculation-details": "cLg",
  "btn": "cLD"
};
