/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { ONBOARDING_TRACKING_EVENTS } from 'qonto/constants/receivable-invoice';

export default class AccountReceivableOnboardingSuccess extends Component {
  lottiePlayer = LottiePlayer;

  @service flow;
  @service segment;
  @service intl;

  get shouldDisplayGotoRecurringInvoicesLink() {
    return this.flow.refererPage.includes('invoice-subscriptions');
  }

  get shouldDisplayImportClientsButton() {
    return (
      this.flow.dataContext.onboardingState.prefilledAt !== null &&
      variation('feature--boolean-ar-import-clients-and-items')
    );
  }

  get subtitleText() {
    return this.shouldDisplayImportClientsButton &&
      variation('feature--boolean-ar-import-clients-and-items')
      ? this.intl.t('receivable-invoices.onboarding.success.subtitle-import-clients')
      : this.intl.t('receivable-invoices.onboarding.success.subtitle');
  }

  @action
  trackOnContinueClick() {
    this.segment.track(ONBOARDING_TRACKING_EVENTS.COMPLETE_CONFIRMED);
  }

  @action
  goToImportClients() {
    this.args.transitionToFlow({
      flowName: 'invoice-clients-import',
      stepId: 'initial',
    });
    this.trackOnContinueClick();
  }
}
