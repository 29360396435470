export default {
  "step-container": "uM",
  "content": "uP",
  "detail": "ul",
  "secondary": "uX",
  "reference": "uC",
  "fees-label": "uk",
  "fees-tooltip": "uJ",
  "separator": "us",
  "mt-32": "uF"
};
