export default {
  "wrapper": "ou",
  "subtitle": "nS body-2",
  "legend": "nc",
  "table": "nq",
  "footer": "nZ",
  "footer-codice-1": "nR",
  "footer-codice-2": "nQ",
  "footer-total-a": "ne",
  "footer-total-b": "nB",
  "footer-balance": "nE",
  "errors": "nd"
};
