export default {
  "wrapper": "ns",
  "subtitle": "nF body-2",
  "legend": "ng",
  "table": "nD",
  "footer": "nV",
  "footer-total-c": "nL",
  "footer-total-d": "nT",
  "footer-balance": "nz"
};
