/* import __COLOCATED_TEMPLATE__ from './initial.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { NextStep } from 'qonto/react/components/flows/invoices/import-clients';

export default class FlowsInvoicesClientsImportInitial extends Component {
  @service intl;

  @reads('args.context') context;

  nextStepComponent = NextStep;

  goToStep = stepId => {
    this.args.context.nextStepId = stepId;
    this.args.transitionToNext();
  };
}
