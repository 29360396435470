/* import __COLOCATED_TEMPLATE__ from './mapping-column.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class MappingColumnComponent extends Component {
  get selectedOption() {
    let selectedValue = this.args.mapping?.[this.args.column];
    return this.args.options.find(o => o.id === selectedValue) ?? {};
  }

  get data() {
    return this.args.rows.slice(0, 5).map(row => row[this.args.column]);
  }

  @action
  onChange(option) {
    this.args.onMappingUpdate(this.args.column, option.id);
  }
}
