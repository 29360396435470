export default {
  "container": "cBf",
  "has-background": "cBK",
  "wrapper": "cBG",
  "error-wrapper": "cBr",
  "bottom-btns": "cBb",
  "bottom-wrapper": "cBy",
  "tab-nav": "cBU",
  "tab-nav-small": "cBj",
  "btn-clear": "cBW",
  "error-illustration": "cBu",
  "error-title": "cES title-1",
  "disclaimer-wrapper": "cEc",
  "disclaimer": "cEq",
  "error-description": "cEZ body-2",
  "table-wrapper": "cER",
  "title": "cEQ title-1",
  "lineup-toggle": "cEe",
  "description": "cEB body-2",
  "loading-wrapper": "cEE",
  "card": "cEd",
  "plan": "cEI",
  "placeholder-header": "cEt",
  "disclaimer-info": "cEM",
  "spinner": "cEP"
};
