export default {
  "body-loading": "cgo",
  "body-members": "cgn",
  "body-details": "cgi",
  "body-details-title": "cgA",
  "icon": "cgY",
  "body-details-header": "cgh",
  "body-details-ids-subtitle": "cgf",
  "body-details-overview-subtitle": "cgK",
  "body-details-overview-content": "cgG",
  "body-details-ctas": "cgr",
  "body-details-ctas-left": "cgb",
  "body-details-ctas-right": "cgy",
  "right-edit": "cgU"
};
