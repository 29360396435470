export default {
  "details": "cVC",
  "details--ff": "cVk",
  "header-container": "cVJ",
  "icon": "cVs",
  "line-container": "cVF",
  "info-line": "cVg",
  "body-details": "cVD",
  "body-details-header": "cVV",
  "body-details-1": "cVL",
  "body-details-2": "cVT",
  "body-details-3": "cVz",
  "body-details-4": "cVa"
};
