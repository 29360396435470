export default {
  "card": "bH",
  "asset": "bO",
  "container": "bm",
  "subtitle": "bp",
  "header": "bx",
  "header-name": "bw",
  "option-list": "bN",
  "feature": "bv",
  "highlighted": "bo"
};
