export default {
  "invite-review": "cZg",
  "invite-review-info": "cZD",
  "invite-review-info--invitee": "cZV",
  "disclaimer-button": "cZL",
  "info-block": "cZT",
  "ev": "cZz",
  "tooltip": "cZa",
  "description": "cZH"
};
