export default {
  "wrapper": "vg",
  "content": "vD",
  "compact": "vV",
  "full-width": "vL",
  "left": "vT",
  "right-image": "vz",
  "right-lottie": "va",
  "badge": "vH",
  "badge-icon": "vO",
  "animation": "vm",
  "hidden": "vp"
};
