export default {
  "container": "Kg",
  "summary": "KD",
  "sideview": "KV",
  "steps-container": "KL",
  "invoice-upload-container": "KT",
  "pdf-preview": "Kz",
  "uploader": "Ka",
  "file-dropzone": "KH",
  "overlay": "KO",
  "footer": "Km",
  "upload-prompt": "Kp",
  "step-selector": "Kx",
  "disclaimer": "Kw"
};
