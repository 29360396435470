export default {
  "selector": "by",
  "metal-design": "bU",
  "content": "bj",
  "header": "bW",
  "big": "bu",
  "header-name": "yS",
  "subtitle": "yc",
  "button": "yq",
  "features": "yZ",
  "feature": "yR",
  "highlighted": "yQ"
};
