export default {
  "wrapper": "cBJ",
  "confirm": "cBs",
  "plan-info": "cBF",
  "plan-info-left": "cBg",
  "info-title": "cBD body-1",
  "initial-trial-content": "cBV",
  "extra-fees-item": "cBL body-2",
  "extra-fees-container": "cBT",
  "bottom-wrapper": "cBz",
  "total-price-container": "cBa",
  "extra-fees-element": "cBH body-1",
  "border": "cBO",
  "subtitle": "cBm body-2",
  "group-item": "cBp",
  "amount": "cBx",
  "subscription-billing-summary": "cBw",
  "summary": "cBN",
  "summary-list": "cBv",
  "disclaimer": "cBo",
  "disclaimer-revamp": "cBn",
  "error": "cBi",
  "error-link": "cBA",
  "warning-wrapper": "cBY",
  "warning-label": "cBh body-1"
};
