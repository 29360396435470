export default {
  "guests-loading": "cPn",
  "header": "cPi",
  "body": "cPA",
  "members": "cPY",
  "title": "cPh caption-bold",
  "placeholder-container": "cPf",
  "icon": "cPK",
  "details": "cPG"
};
