/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class F24ManualDeclarationSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  constructor() {
    super(...arguments);
    this.segment.track('f24_create-flow_step6-order-created');
  }
}
