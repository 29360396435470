export default {
  "details": "cQk",
  "disclaimer": "cQJ",
  "data": "cQs",
  "confirm": "cQF",
  "form": "cQg",
  "textarea": "cQD",
  "preview": "cQV",
  "form-title": "cQL title1",
  "form-subtitle": "cQT title2",
  "divider": "cQz",
  "submit": "cQa",
  "doc": "cQH",
  "amount-input-error": "cQO"
};
