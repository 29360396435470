/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { GenderSelect } from 'qonto/react/components/gender-select';
import {
  getUboConnectionToOrganizationOptions,
  isPrimaryUboConnectionToOrganization,
} from 'qonto/utils/ubo';

export default class MembershipEditFormComponent extends Component {
  disclaimerInline = Disclaimer.Inline;
  genderSelect = GenderSelect;

  disclaimerBlock = Disclaimer.Block;

  @service modals;
  @service intl;

  previewModal;

  get isEditMode() {
    return this.args.mode === 'edit';
  }

  get shouldDisplayOtherNationalities() {
    return variation('feature--boolean-missing-kyb-fields');
  }

  get shouldShowMissingInformation() {
    return this.isEditMode && !this.args.showValidations;
  }

  get shouldShowMissingInformationDisclaimer() {
    let {
      firstName,
      lastName,
      nationality,
      birthdate,
      birthCity,
      addressFirstLine,
      addressZipCode,
      addressCity,
      addressCountry,
      taxCountry,
      needsTaxNumber,
      legalRepresentative,
      ubo,
      taxIdentificationNumber,
    } = this.args.membershipChangeRequest;

    if (!this.isEditMode) {
      return false;
    }

    return (
      !firstName ||
      !lastName ||
      !nationality ||
      !birthdate ||
      !birthCity ||
      !addressCity ||
      !addressFirstLine ||
      !addressZipCode ||
      !addressCountry ||
      (ubo && !taxCountry) ||
      (ubo && needsTaxNumber && !taxIdentificationNumber) ||
      // Files
      (legalRepresentative && !this.args.kbis.length)
    );
  }

  get corporateOfficerErrorMessage() {
    return (
      this.args.showValidations &&
      this.args.membershipChangeRequest.validations.attrs.corporateOfficer.message
    );
  }

  get legalRepresentativeErrorMessage() {
    return (
      this.args.showValidations &&
      this.args.membershipChangeRequest.validations.attrs.legalRepresentative.message
    );
  }

  get uboErrorMessage() {
    return (
      this.args.showValidations && this.args.membershipChangeRequest.validations.attrs.ubo.message
    );
  }

  get usTaxPayerMessage() {
    return (
      this.args.showValidations &&
      this.args.membershipChangeRequest.validations.attrs.usTaxPayer.message
    );
  }

  get taxPayerOutsideLegalCountryMessage() {
    return (
      this.args.showValidations &&
      this.args.membershipChangeRequest.validations.attrs.taxPayerOutsideLegalCountry.message
    );
  }

  get isGermanOrganization() {
    return this.args.organization.legalCountry === 'DE';
  }

  get showUsRelatedTaxInformations() {
    if (this.args.mode === 'add') return false;

    return !this.isGermanOrganization && this.args.membershipChangeRequest.taxCountry !== null;
  }

  get showTaxPayerOutsideLegalCountry() {
    if (this.args.mode === 'add') return false;

    return (
      (this.args.membershipChangeRequest.taxCountry === 'FR' ||
        this.args.membershipChangeRequest.taxCountry === 'IT') &&
      !this.isGermanOrganization
    );
  }

  get isNameDisabled() {
    return this.args.membershipChangeRequest.membership.get('owner');
  }

  get shouldDisplayUboConnectionToOrganization() {
    return (
      variation('feature--boolean-missing-ubo-fields') &&
      this.args.organization.isItalianShareCapitalCompany
    );
  }

  get isUboConnectionToOrganizationFieldOptional() {
    return this.shouldDisplayUboConnectionToOrganization && !this.args.membershipChangeRequest.ubo;
  }

  get uboConnectionToOrganizationFieldTitle() {
    return this.args.membershipChangeRequest.ubo === true
      ? this.intl.t('organizations.profile.owner-form.ubo-connection-dropdown.title')
      : this.intl.t('organizations.profile.owner-form.ubo-connection-dropdown.title-optional');
  }

  get uboConnectionToOrganizationOptions() {
    return getUboConnectionToOrganizationOptions(this.intl);
  }

  get selectedUboConnectionToOrganization() {
    return this.uboConnectionToOrganizationOptions.find(
      ({ value }) => value === this.args.membershipChangeRequest.uboConnectionToOrganization
    );
  }

  @action
  changeUboConnectionToOrganization({ value }) {
    this.args.membershipChangeRequest.uboConnectionToOrganization = value;
    this.args.membershipChangeRequest.uboConnectionToOrganizationRaw =
      isPrimaryUboConnectionToOrganization(value) ? value : null;
  }

  @action
  updateBirthDate(value) {
    // As we now use DateFieldV2, the value we receive from the component
    // is formatted as a YYYY-MM-DD string date
    if (value) {
      this.args.membershipChangeRequest.birthdate = value;
    }
  }

  @action
  updateMembershipNationalities(countryCodes) {
    this.args.membershipChangeRequest.nationalities = countryCodes;
  }

  @action
  handlePoiUploaded(file) {
    this.args.setShowEmptyPoiError(false);
    this.args.pois.push(file);
  }

  @action
  handleKbisUploaded(file) {
    this.args.setShowEmptyKbisError(false);
    this.args.kbis.push(file);
  }

  @action
  handleDeleteKbis(file) {
    file.deleteRecord();
    let index = this.args.kbis.indexOf(file);
    this.args.kbis.splice(index, 1);
    this.previewModal?.close();
  }

  @action
  handleDeletePoi(file) {
    file.deleteRecord();
    let index = this.args.pois.indexOf(file);
    this.args.pois.splice(index, 1);
    this.previewModal?.close();
  }

  @action
  handlePreviewFile(file) {
    this.previewModal = this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      selectedFile: file,
      deletable: false,
    });
  }

  @action
  handleChangeTaxCountry(taxCountry) {
    this.args.membershipChangeRequest.taxCountry = taxCountry;

    // If Tax Country already exists on the original Organization,
    // => Then we use the Tax Identification Number associated to this country
    // => If not we clear the field
    let { membership } = this.args;
    if (membership && membership.taxResidenceCountry === taxCountry) {
      this.args.membershipChangeRequest.taxIdentificationNumber =
        membership.taxResidenceTaxIdentificationNumber;
    } else if (taxCountry !== this.args.membershipChangeRequest.taxResidenceCountry) {
      this.args.membershipChangeRequest.taxIdentificationNumber = '';
    }

    if (
      !this.isGermanOrganization &&
      this.args.mode === 'edit' &&
      this.args.membershipChangeRequest.owner &&
      taxCountry === 'US'
    ) {
      this.args.membershipChangeRequest.usTaxPayer = true;
    }
  }

  @action handleCorpOfficerChange(value) {
    this.args.membershipChangeRequest.corporateOfficer = value;

    if (!value) {
      this.args.membershipChangeRequest.legalRepresentative = false;
    } else {
      this.args.membershipChangeRequest.legalRepresentative = null;
    }
  }
}
