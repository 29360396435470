/* import __COLOCATED_TEMPLATE__ from './mapping.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import { TrackedObject } from 'tracked-built-ins';

import { TEMPLATES } from 'qonto/constants/clients-import-flow';
import { apiBaseURL, clientHubNamespace } from 'qonto/constants/hosts';
import { formatPayload } from 'qonto/utils/invoices/imports/format-payload';
import { matchTemplate } from 'qonto/utils/invoices/imports/match-template';
import { prepareColumns } from 'qonto/utils/invoices/imports/prepare-columns';

export default class FlowsInvoicesClientsImportMapping extends Component {
  @service intl;
  @service networkManager;
  @service toastFlashMessages;
  @reads('args.context.xlsFile') xlsFile;
  @reads('args.context') context;
  @reads('args.context.xlsFile.columns') columns;
  @reads('args.context.xlsFile.rows') rows;
  @reads('args.context.mapping') mapping;

  constructor(...props) {
    super(...props);

    let { mapping, colsToHide } = matchTemplate(this.xlsFile, TEMPLATES);
    this.context.mapping = new TrackedObject(mapping);
    prepareColumns(this.xlsFile, mapping, colsToHide);
  }

  button = Button;
  disclaimer = Disclaimer.Block;

  @tracked nameNotMappedWarning = false;

  get stepSubtitle() {
    return this.intl.t('clients-import.mapping.subtitle', {
      number: this.rows?.length || 0,
    });
  }

  get warningMessage() {
    return this.intl.t('clients-import.mapping.errors.missing-client-name', {
      count: this.rows?.length || 0,
    });
  }

  get options() {
    return [
      { id: 'kind', name: this.intl.t('clients-import.mapping.column.dropdown.client-type') },
      { id: 'name', name: this.intl.t('clients-import.mapping.column.dropdown.company-name') },
      {
        id: 'first_name',
        name: this.intl.t('clients-import.mapping.column.dropdown.first-name'),
      },
      {
        id: 'last_name',
        name: this.intl.t('clients-import.mapping.column.dropdown.last-name'),
      },
      {
        id: 'email',
        name: this.intl.t('clients-import.mapping.column.dropdown.email-address'),
      },
      {
        id: 'billing_address.street_address',
        name: this.intl.t('clients-import.mapping.column.dropdown.billing-address.street'),
      },
      {
        id: 'billing_address.zip_code',
        name: this.intl.t('clients-import.mapping.column.dropdown.billing-address.zip-code'),
      },
      {
        id: 'billing_address.city',
        name: this.intl.t('clients-import.mapping.column.dropdown.billing-address.city'),
      },
      {
        id: 'billing_address.province_code',
        name: this.intl.t('clients-import.mapping.column.dropdown.billing-address.province'),
      },
      {
        id: 'billing_address.country_code',
        name: this.intl.t('clients-import.mapping.column.dropdown.billing-address.country'),
      },
      {
        id: 'delivery_address.street_address',
        name: this.intl.t('clients-import.mapping.column.dropdown.delivery-address.street'),
      },
      {
        id: 'delivery_address.zip_code',
        name: this.intl.t('clients-import.mapping.column.dropdown.delivery-address.zip-code'),
      },
      {
        id: 'delivery_address.city',
        name: this.intl.t('clients-import.mapping.column.dropdown.delivery-address.city'),
      },
      {
        id: 'delivery_address.province_code',
        name: this.intl.t('clients-import.mapping.column.dropdown.delivery-address.province'),
      },
      {
        id: 'delivery_address.country_code',
        name: this.intl.t('clients-import.mapping.column.dropdown.delivery-address.country'),
      },
      {
        id: 'tin_number',
        name: this.intl.t('clients-import.mapping.column.dropdown.tax-number'),
      },
      { id: 'locale', name: this.intl.t('clients-import.mapping.column.dropdown.language') },
      { id: 'currency', name: this.intl.t('clients-import.mapping.column.dropdown.currency') },
    ];
  }

  @action
  onMappingUpdate(column, selectedField) {
    this.mapping[column] = selectedField;
  }

  onConfirm = dropTask(async () => {
    this.nameNotMappedWarning = false;

    let mappedValues = Object.values(this.mapping);

    let isCompanyNameMapped = mappedValues.includes('name');
    let isClientNameMapped =
      mappedValues.includes('first_name') && mappedValues.includes('last_name');

    if (!isCompanyNameMapped && !isClientNameMapped) {
      this.nameNotMappedWarning = true;
      return;
    }

    let payload = formatPayload(this.xlsFile, this.mapping, 'clients-import');

    try {
      let response = await this.networkManager.rawRequest(
        `${apiBaseURL}/${clientHubNamespace}/clients/imports`,
        {
          method: 'POST',
          data: payload,
        }
      );

      if (response.status !== 202) {
        throw new Error('Unexpected response status');
      }

      let { data } = await response.json();
      let {
        attributes: { status },
      } = data;

      if (status === 'failed') {
        throw new Error('Failed to import clients');
      }

      this.args.transitionToNext();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });
}
