/* import __COLOCATED_TEMPLATE__ from './claim.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { CLAIM_TYPES } from 'qonto/constants/transactions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const CLAIM_TYPE_OUTDATED_THRESHOLDS = {
  [CLAIM_TYPES.fraud]: 56,
  [CLAIM_TYPES.commercial]: 90,
  [CLAIM_TYPES.atm]: 90,
};

const CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD_ATM = 396;

export default class HelpCenterSelectedItemClaimComponent extends Component {
  @service flowLinkManager;
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;

  get isAtmClaim() {
    return this.args.selectedClaimType === CLAIM_TYPES.atm;
  }

  get isCommercialClaim() {
    return this.args.selectedClaimType === CLAIM_TYPES.commercial;
  }

  get isFraudClaim() {
    return this.args.selectedClaimType === CLAIM_TYPES.fraud;
  }

  get isPending() {
    let transaction = this.args.highlightedItem;

    return transaction?.pending;
  }

  get isFailing() {
    let transaction = this.args.highlightedItem;
    return transaction?.canceled || transaction?.declined || transaction?.reversed;
  }

  get isItalianOrga() {
    return this.organizationManager.organization.isItalian;
  }

  get errorText() {
    let transaction = this.args.highlightedItem;

    if (transaction?.canceled) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.canceled'
      );
    } else if (transaction?.declined) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.declined'
      );
    } else if (transaction?.reversed) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.reverted'
      );
    }
  }

  get isItalianExtendedClaim() {
    let { selectedClaimType } = this.args;
    return (
      this.isItalianOrga &&
      (selectedClaimType === CLAIM_TYPES.fraud || selectedClaimType === CLAIM_TYPES.atm)
    );
  }

  get isOutdated() {
    let { highlightedItem, selectedClaimType } = this.args;
    let transaction = highlightedItem;
    let settledDay = dayjs(transaction?.settledAt);

    // A special case for Italian fraud/atm claims
    if (this.isItalianExtendedClaim) {
      return dayjs().diff(settledDay, 'day') > CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD_ATM;
    }

    let threshold = CLAIM_TYPE_OUTDATED_THRESHOLDS[selectedClaimType];
    return dayjs().diff(settledDay, 'day') > threshold;
  }

  get isPendingClaim() {
    let transaction = this.args.highlightedItem;

    let hasPendingClaims = transaction?.chargebackDisputingTypes?.length > 0;

    return hasPendingClaims;
  }

  get isDisputedClaim() {
    let transaction = this.args.highlightedItem;

    let isDisputedClaim = transaction?.chargebackDisputedTypes?.includes(
      this.args.selectedClaimType
    );

    return isDisputedClaim;
  }

  get validityPeriodText() {
    let { selectedClaimType } = this.args;
    // A special case for Italian fraud/atm claims
    if (this.isItalianExtendedClaim) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.too-old.description.italian-validity-days'
      );
    }
    let threshold = CLAIM_TYPE_OUTDATED_THRESHOLDS[selectedClaimType];
    switch (threshold) {
      case 90:
        return this.intl.t(
          'transactions.sidebar.helper.dispute.errors.too-old.description.validity-days'
        );
      case 56:
        return this.intl.t(
          'transactions.sidebar.helper.dispute.errors.too-old.description.validity-weeks'
        );
    }
  }

  _disputeStartTask = dropTask(async () => {
    this.segment.track('cards-chargeback_start-dispute-cta_clicked', {
      type: this.args.selectedClaimType,
    });

    let { highlightedItem, onClose, selectedClaimType } = this.args;
    let card = await highlightedItem.subject;

    this.flowLinkManager.transitionTo({
      name: 'chargeback-claim',
      stepId: this.isFraudClaim && card?.isActive ? 'block-card' : 'transactions-selection',
      queryParams: {
        ...this.router.currentRoute?.queryParams,
        transactionId: highlightedItem.id,
        claimType: selectedClaimType,
      },
    });

    onClose?.();
  });

  @action
  onDisputeStart() {
    this._disputeStartTask.perform().catch(ignoreCancelation);
  }
}
