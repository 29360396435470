export default {
  "bucket-item": "clw",
  "button": "clN",
  "content": "clv",
  "bucket-icon": "clo",
  "bucket-title-container": "cln",
  "bucket-new-badge": "cli",
  "bucket-title": "clA",
  "arrow-icon": "clY"
};
