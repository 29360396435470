export default {
  "wrapper": "cqD",
  "promotion-title": "cqV",
  "comparison-table": "cqL",
  "table-head": "cqT",
  "title-wrapper": "cqz",
  "illustration-wrapper": "cqa",
  "illustration": "cqH",
  "role-wrapper": "cqO",
  "role-name": "cqm",
  "role-disabled": "cqp",
  "role-access": "cqx",
  "disabled-caption": "cqw",
  "table-head-sticky": "cqN",
  "show-border": "cqv",
  "group-row": "cqo",
  "table-body": "cqn",
  "group": "cqi",
  "table-body-row": "cqA",
  "feature-description": "cqY body-2",
  "feature": "cqh body-2",
  "group-spacer": "cqf"
};
