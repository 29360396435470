export default {
  "row": "iD",
  "row-active": "iV",
  "cell": "iL body-2",
  "terminated": "iT",
  "amount": "iz body-1",
  "item-name-cell": "ia",
  "status-icon": "iH",
  "item-name-container": "iO",
  "item-info": "im",
  "name": "ip body-1",
  "status-displayed": "ix caption"
};
