export default {
  "header": "csV",
  "center": "csL",
  "header-placeholder": "csT",
  "placeholder": "csz",
  "name-block": "csa",
  "block": "csH",
  "detail-block": "csO",
  "border-top": "csm"
};
