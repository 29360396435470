export default {
  "container": "yH",
  "centered": "yO",
  "content": "ym",
  "spinner": "yp",
  "holder-selector": "yx",
  "holder-selector-error": "yw",
  "info-text": "yN",
  "error-text": "yv",
  "lottie-illustration": "yo",
  "info-text-1": "yn",
  "info-text-2": "yi"
};
